<div class="card">
  <div class="card-header">
    <h2 class="card-title">Call protected API</h2>
  </div>
  <div class="card-body">
    <button *ngIf="isAdmin" class="btn btn-primary" (click)="callMyProtectedAdminApiController()">
      Call [Authorize(Roles = "Admin")] API
    </button>

    <button *ngIf="isAdmin || isUser" class="btn btn-default" (click)="callMyProtectedApiController()">
      Call [Authorize] API
    </button>

    <button class="btn btn-warning" (click)="callMyProtectedEditorsApiController()">
      Call [Authorize(Roles = "Editor")] API
    </button>

    <div *ngIf="result" class="highlight">
      <pre><code>{{result | json}}</code></pre>
    </div>
  </div>
</div>

<div class="top15">
  <div class="card">
    <div class="card-header">
      <h2 class="card-title">Show/Hide elements using isVisibleForAuthUser directive</h2>
    </div>
    <div class="card-body">
      <div class="alert alert-info" isVisibleForAuthUser>
        Is-Visible-For-AuthUser
      </div>
      <div class="alert alert-success" isVisibleForAuthUser [isVisibleForRoles]="['Admin','User']">
        Is-Visible-For-Roles = ['Admin','User']
      </div>
    </div>
  </div>
</div>

<div class="top15">
  <div class="card">
    <div class="card-header">
      <h2 class="card-title">Show/Hide elements using *hasAuthUserViewPermission directive</h2>
    </div>
    <div class="card-body">
      <div class="alert alert-info" *hasAuthUserViewPermission="">
        *hasAuthUserViewPermission=""
      </div>
      <div class="alert alert-success" *hasAuthUserViewPermission="['Admin','User']">
        *hasAuthUserViewPermission="['Admin','User']"
      </div>
    </div>
  </div>
</div>
