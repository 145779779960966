<!--<nav class="navbar navbar-light bg-light navbar-expand-md">
  <ul class="nav navbar-nav">
    <li *ngIf="isLoggedIn" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="nav-link" [routerLink]="['/welcome']">Home</a>
    </li>
    <li *ngIf="isLoggedIn" class="nav-item" routerLinkActive="active">
      <a class="nav-link" queryParamsHandling="merge" [routerLink]="['/login']">Login</a>
    </li>
    <li *ngIf="isLoggedIn" class="nav-item" routerLinkActive="active">
      <a class="nav-link" (click)="logout()">Logoff [{{displayName}}]</a>
    </li>
    <li *ngIf="isLoggedIn" class="nav-item" routerLinkActive="active">
      <a class="nav-link" [routerLink]="['/protectedPage']">Protected Page</a>
    </li>
    <li *ngIf="isLoggedIn" class="nav-item" routerLinkActive="active">
      <a class="nav-link" [routerLink]="['/callProtectedApi']">‍‍Call Protected Api</a>
    </li>
    <li *ngIf="isLoggedIn" class="nav-item" routerLinkActive="active">
      <a class="nav-link" [routerLink]="['/changePassword']">Change Password</a>
    </li>
  </ul>
</nav>-->
<nav *ngIf="isLoggedIn" class="navbar navbar-light bg-light navbar-expand-md">
<ul class="nav cbp-vimenu">
  <li><i class="fa fa-users fa-3x nav-link cbp-vicurrent" [routerLink]="['/users']"></i></li>
  <li><i class="fa fa-list fa-3x nav-link" aria-hidden="true"></i></li>
  <li><i class="fa fa-briefcase fa-3x nav-link" aria-hidden="true"></i></li>
  <li><i class="fa fa-area-chart fa-3x nav-link" aria-hidden="true"></i></li>
  <!-- Example for active item:
  <li class="cbp-vicurrent"><a href="#" class="icon-pencil">Pencil</a></li>
  -->
  <p></p>
  <li><i id="logout-icon" class="fa fa-user fa-3x nav-link" aria-hidden="true"></i></li>
</ul>
</nav>
