import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, Report, Unit, Building, ComplianceNotification } from '@app/core';
import { map } from 'rxjs/operators';
import { UnitLockAndUnlock } from '@app/core/models/UnitLockAndUnlock';

@Injectable({ providedIn: 'root' })
export class UnitDetailsService {
  constructor(private http: HttpClient) { }

  getUnits(propertyID: number) {
    return this.http.get<{ list: Unit[] }>(`${AppConfig.apiEndpoint}/unitDetails/1/10000/${propertyID}`)
      .pipe(
        map(response => {
          return response;
        })
      );
  }//
  getAllBuildings(propertyID: number) {
    return this.http.get<{ list: Building[] }>(`${AppConfig.apiEndpoint}/unitDetails/getBuildings/1/10000/${propertyID}`)
      .pipe(
      map(response => {
        return response;
        })
      );
  }


  updateBuilding(building: Building) {
    return this.http.put(`${AppConfig.apiEndpoint}/unitDetails/updateBuilding`, building);
  }

  addBuilding(building: Building) {
    return this.http.put(`${AppConfig.apiEndpoint}/unitDetails/addBuilding`, building);
  }

  deleteBuilding(id: number) {
    return this.http.delete(`${AppConfig.apiEndpoint}/unitDetails/deleteBuilding/${id}`);
  }

  addUnit(unit: Unit) {
    return this.http.post(`${AppConfig.apiEndpoint}/unitDetails`, unit);
  }

  update(unit: Unit) {
    if(unit.moveInDate){
      unit.moveInDate = unit.moveInDate.toLocaleString();
    }
    if(unit.moveOutDate){
      unit.moveOutDate = unit.moveOutDate.toLocaleString()
    }
    if(unit.certEffectDate){
      unit.certEffectDate =unit.certEffectDate.toLocaleString()
    }
    if(unit.tempDateVacated){
      unit.tempDateVacated = unit.tempDateVacated.toLocaleString()
    }
    if(unit.tempDateReturned){
      unit.tempDateReturned = unit.tempDateReturned.toLocaleString()
    }
    if(unit.previousCertEffectDate){
      unit.previousCertEffectDate =unit.previousCertEffectDate.toLocaleString()
    }
    if(unit.dateOfTransfer){
      unit.dateOfTransfer =unit.dateOfTransfer.toLocaleString()
    }
    return this.http.put(`${AppConfig.apiEndpoint}/unitDetails`, unit);
  }

  delete(id: number) {
    return this.http.delete(`${AppConfig.apiEndpoint}/unitDetails/${id}`);
  }

  getAllProperties() {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/all`);
  }

  notifyCompliance(model: ComplianceNotification){
    return this.http.post(`${AppConfig.apiEndpoint}/unitDetails/Notify`, model)
  }

  
  getQualifiedUnits(buildingID: number) {
    return this.http.get<number>(`${AppConfig.apiEndpoint}/unitDetails/qualifiedUnits/${buildingID}`)
      .pipe(
        map(response => {
          return response;
        })
      );
  }
  getSetAside(buildingID: number) {
    return this.http.get<any[]>(`${AppConfig.apiEndpoint}/unitDetails/setAside/${buildingID}`)
      .pipe(
        map(response => {
          return response;
        })
      );
  }
  getSetAsideProperty(propertyID: number) {
    return this.http.get<any[]>(`${AppConfig.apiEndpoint}/unitDetails/setAsideProperty/${propertyID}`)
      .pipe(
        map(response => {
          return response;
        })
      );
  }
  getMarketUnitsProperty(propertyID: number) {
    return this.http.get<number>(`${AppConfig.apiEndpoint}/unitDetails/marketUnitsProperty/${propertyID}`)
      .pipe(
        map(response => {
          return response;
        })
      );
  }
  getFileRatingTypes() {
    return this.http.get<any[]>(`${AppConfig.apiEndpoint}/unitDetails/getFileRatingTypes`)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  getSeniorTypes() {
    return this.http.get<any[]>(`${AppConfig.apiEndpoint}/unitDetails/getSeniorTypes`)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  getAccessibleTypes() {
    return this.http.get<any[]>(`${AppConfig.apiEndpoint}/unitDetails/getAccessibleTypes`)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  lockAndUnlock(lockAndUnlockModel: UnitLockAndUnlock) {
    return this.http.put(`${AppConfig.apiEndpoint}/unitDetails/unitRowLockAndUnlock`, lockAndUnlockModel);
  }

  lockAndUnlockAll(lockAndUnlockArray: any[]) {
    return this.http.put(`${AppConfig.apiEndpoint}/unitDetails/lockAndUnlockAll`, lockAndUnlockArray);
  }

}
