import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { SharedModule } from "./shared/shared.module";
import { WelcomeComponent } from "./welcome/welcome.component";
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { AppConfig } from "./core/services/app.config";
import { ChangePasswordComponent } from "./authentication/change-password/change-password.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatInputModule } from '@angular/material/input';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { NotifyReasonDialogComponent } from "./shared/dialogs/notify-reason-dialog/notify-reason-dialog.component";
import { CommonModule, CurrencyPipe, PercentPipe } from "@angular/common";
import { FilesDialogComponent } from "./shared/dialogs/files-dialog/files-dialog.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { environment } from '@env/environment';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import {
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalInterceptorConfiguration,
    MSAL_GUARD_CONFIG,
    MsalGuardConfiguration,
    MsalBroadcastService,
    MsalService,
    MsalGuard,
    MsalRedirectComponent,
    MsalModule,
    MsalInterceptor,
  } from '@azure/msal-angular';



export function loggerCallback(logLevel: LogLevel, message: string) {
    if(logLevel== LogLevel.Info)
    console.log(message);
  }
  
  export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
      auth: {
        clientId: environment.auth.clientId,
        authority: environment.auth.authority,
        redirectUri: '/ssoauth',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: environment.auth.navigateToLoginRequestUrl
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage
      },
      system: {
        allowNativeBroker: false, // Disables WAM Broker
        
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false
        }
      }
    });
  }
  
  export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(`${environment.apiUrl}/account/ssoAuth`, ['api://9b5738b7-605e-4750-87f8-0ae40516e3b4/api-access']);
  
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap
    };
  }

@NgModule({
    declarations: [
        AppComponent,
        WelcomeComponent,
        PageNotFoundComponent,
        ChangePasswordComponent,
      
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        SharedModule.forRoot(),
        DashboardModule,
        AppRoutingModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatInputModule,
        ModalModule.forRoot(),
        ToastrModule.forRoot({
      positionClass: 'toast-top-center'
 }), 
        MsalModule,
    ],
    providers: [
        
       
        BsModalRef,
        NgbActiveModal,
        //DropDownEditDialogComponent,
        CurrencyPipe,
        PercentPipe,
        FilesDialogComponent,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
          },
         
          {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
          },
          MsalService,
    MsalGuard,
    ],
    bootstrap: [AppComponent,MsalRedirectComponent]
})
export class AppModule { }
