import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '@app/core';
import { map } from 'rxjs/operators';
import { TrackingItem } from '@app/core'
import { FrequencyType } from '@app/core/models/frequencyType';

@Injectable({ providedIn: 'root' })
export class FrequencyAddDialogService {
    constructor(private http: HttpClient) { }
    
    // getProjectSummaryTitles() {
    //     return this.http.get<TrackingItem[]>(`${AppConfig.apiEndpoint}/ProjectSummaryNoteTitle/`)
    //         .pipe(
    //             map(
    //                 response =>{
    //                     return response;
    //                 }
    //             )
    //         );
    // }


    // getProjectSummaryTitlesByHeaderID(headerID: number) {
    //     return this.http.get<ProjectSummaryNoteTitle[]>(`${AppConfig.apiEndpoint}/ProjectSummaryNoteTitle/${headerID}/`)
    //         .pipe(
    //             map(
    //                 response =>{
    //                     return response;
    //                 }
    //             )
    //         );
    // }

    addFrequencyType(model: FrequencyType){
        return this.http.post<number>(`${AppConfig.apiEndpoint}/preventiveMaintenance/addFrequencyType`, model);
    }


    updateFrequencyType(model: FrequencyType){
        return this.http.put(`${AppConfig.apiEndpoint}/preventiveMaintenance/updateFrequencyType`, model);
    }

    deleteFrequencyType(frequencyTypeID: number){
        return this.http.delete(`${AppConfig.apiEndpoint}/preventiveMaintenance/deleteFrequencyType/${frequencyTypeID}`);
    }

}
