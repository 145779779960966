<div class="row wrapper">
  <div class=" order-2 order-lg-1 col-xs-12 col-lg nav-col border-right d-flex flex-column">
    <div>
      <h6 class="mt-3 filter-title">Table Columns: </h6>
    </div> 
    <div class="list-group-wrapper">
      <ul class="list-group list-group-flush">
        <li class="list-group-item list-group-item-action py-1 px-1" *ngFor="let column of gridColumns">
          <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" [attr.id]="'col-' + column.colId"
                (change)="toggleColumn(column.colId)"
                [checked]="column.visible">
              <label class="custom-control-label d-block" [attr.for]="'col-' + column.colId">
                {{ column.headerName }}
              </label>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="order-1 order-lg-2 col-xs-12 col-lg d-flex flex-column">
    <nav class="navbar navbar-expand-lg navbar-light">

      <div *ngIf="user">
        <mat-radio-group [(ngModel)]="userList" (ngModelChange)="userListChange($event)">
          <mat-radio-button value="wnc">WNC Users</mat-radio-button>
          <mat-radio-button value="cpp">CPP Users</mat-radio-button>
          <mat-radio-button value="pef">PEF Users</mat-radio-button>
          <mat-radio-button value="all">All Users</mat-radio-button>
        </mat-radio-group>
      </div>

      <div *ngIf="company">
        <mat-radio-group [(ngModel)]="companyList" (ngModelChange)="companyListChange($event)">
          <mat-radio-button value="wnc">WNC Companies</mat-radio-button>
          <mat-radio-button value="cpp">CPP Companies</mat-radio-button>
          <mat-radio-button value="pef">PEF Companies</mat-radio-button>
          <mat-radio-button value="all">All Companies</mat-radio-button>
        </mat-radio-group>
      </div>

      <div *ngIf="property">
        <mat-radio-group [(ngModel)]="propertyList" (ngModelChange)="propertyListChange($event)">
          <mat-radio-button value="wnc">WNC Properties</mat-radio-button>
          <mat-radio-button value="cpp">CPP Properties</mat-radio-button>
          <mat-radio-button value="pef">PEF Properties</mat-radio-button>
          <mat-radio-button value="all">All Properties</mat-radio-button>
        </mat-radio-group>
      </div>

      <button class="btn btn-outline-secondary" *ngIf="hasFilters" (click)="clearFilters()">
        Clear filters
      </button>

      <button *ngIf="units" class="btn btn-outline-secondary" (click)="selectAllDeselectCloumns()">
        Select/Deselect All
      </button>
      <span class="mr-auto"></span>

      <ng-container *ngIf="enableExport">
        <button type="button" class="btn btn-outline-primary" (click)="exportGrid()"><i class="fa fa-file-excel-o" aria-hidden="true"></i>	&#160;Export</button>
      </ng-container>

      <ng-content></ng-content>
    </nav>

    <ag-grid-angular
      class="ag-theme-balham"
      [pagination]="true"
      [gridOptions]="gridOptions"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [sideBar]="true"
      [context]="context"
      [components]="frameworkComponents"
      (gridReady)="onGridReady($event)"
      (rowClicked)="onRowClicked($event)"
      (filterChanged)="onFilterChanged($event)"
      (columnVisible)="onColumnVisible($event)"
      [rowData]="rowData"
      [groupSelectsChildren]="true"
    >
    </ag-grid-angular>

</div>
</div>
