import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AuthService } from '@app/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-management-grid-action',
  templateUrl: './management-grid-action.component.html',
  styleUrls: ['./management-grid-action.component.css']
})
export class ManagementGridActionComponent implements ICellRendererAngularComp {

  public params: any;
  public isUserGrid: boolean = false;
  public isUserActive: boolean = true;
  public isAdmin: boolean = false;
  Admin = false;  
  public userEntity: string[] | null | undefined;
  public wnc: boolean = false;
  public cpp: boolean = false;
  public pef: boolean = false;
  public page: string = '';

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,

    ) {
  }

  agInit(params: any): void {
    this.params = params;
    this.isUserGrid = !!this.params.colDef.refData;
    this.isUserActive = this.params.data.isActive;
    this.isAdmin = this.authService.isAuthUserInRole("Admin");
    //this.isAdmin = this.authService.isAuthUserInRole("WNCAdmin");
    if (this.isAdmin === true) {
      this.Admin = true;
    }
    this.userEntity = this.authService.authUserEntity();
    if(this.userEntity){
      //BFS: 1.10.4 - the > -1 checks if it exists in the string array
      if(this.userEntity.indexOf('wnc') > -1){
        this.wnc = true;
      }
      if(this.userEntity.indexOf('cpp') > -1){
        this.cpp = true;
      }
      if(this.userEntity.indexOf('pef') > -1){
        this.pef = true;
      }
    }

    //BFS: 1.10.4 - dont need this but just keep it o see which page you are on
    // console.log('%c management grid', 'background: yellow; color: black', this.route.snapshot.data.title);
    // this.page = this.route.snapshot.data.title;
    // console.log('%c management grid page', 'background: yellow; color: black', this.page);

  }

  // public get pageTitle() {
  //   return this.route.snapshot.data.title;
  // }

  refresh(): boolean {
    return false;
  }

  public onEdit() {
    const actionType = 'edit';
    const data = this.params.data;
    this.params.context.componentParent.onAction(actionType, data);
  }

  public onDelete() {
    const actionType = 'delete';
    const data = this.params.data;
    this.params.context.componentParent.onAction(actionType, data);
  }
}
