import { Component, OnInit, Inject, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
//import { NgbActiveModal, NgbDateAdapter, NgbDateNativeAdapter, NgbModal, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal, NgbDateAdapter, NgbDateNativeAdapter, NgbModal, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { dateValidator2 } from '@app/shared/validators';
import { DatePipe } from '@angular/common';
import { Unit } from '@app/core/models/unit';
import { Building } from '@app/core/models/building';
import { Property } from '@app/core/models/property';
import { UserService } from '@app/users/user.service';
import { UnitDetailsService } from '@app/unitDetails/unitDetails.service';
import {CurrencyMaskModule} from "ng2-currency-mask";
import { PropertyService } from '@app/properties/properties.service';
import { AuthService, Credentials } from "@app/core";
import { merge } from 'rxjs';
import { CustomDateParserFormatter } from '../building-add-dialog/building-add-dialog.component'
//import { DISABLED } from '@angular/forms/src/model';
import { DialogsService } from '@app/core/services/dialogs.service';
import { IsVisibleForAuthUserDirective } from '@app/shared/directives/is-visible-for-auth-user.directive';
import { NotifyReasonDialogComponent } from '../notify-reason-dialog/notify-reason-dialog.component';

@Component({
  selector: 'app-unit-add-dialog',
  templateUrl: './unit-add-dialog.component.html',
  styleUrls: ['./unit-add-dialog.component.scss'],
  //providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, DatePipe]
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
     DatePipe]
})
export class UnitAddDialogComponent implements OnInit {
  dialogTitle = '';
  isAdd = true;
  propertyList: Property[];
  formExemptTop: UntypedFormGroup;
  form: UntypedFormGroup;
  formExempt: UntypedFormGroup;
  formMarket: UntypedFormGroup;
  formVacant: UntypedFormGroup;
  formNonQualifiedOccupied: UntypedFormGroup;
  form3: UntypedFormGroup;
  formPreviouslyTaxCreditQualified: UntypedFormGroup;
  formTenantTransfer: UntypedFormGroup;
  //by default the form should be normal
  formType: string = 'Normal';

  @Input()
  unit!: Unit;
  buildings!: Building[];
  property!: Property;
  addUnit: boolean = false;
  @Output()
  saveChange = new EventEmitter();
  model: any;
  public isFailedForm: boolean = false;
  public companies: any;
  public properties: any;
  public exemptUnitPopover: string = `Exempt employee is a full time employee of the project. (Exempt employee is a full-time employee of the project who is exempt from certification.)`;
  public firstYearTenantPopover: string = `The name should be typed out like: 'Last name, First name'.`;
  public certEffDatePopover: string = 'Date Tenant became tax credit certified.';
  public TenIncomeQualifiedPopover: string = 'Gross Income at Move-In < Move-In Income Limits';
  public TenPaymentPopover: string = 'Unit Rent - Rent Subsidy';
  public TenGrossRentPopover: string = 'Tenant Payment + Utility Allowance + Non Optional Fees';
  public TenRentQualifiedPopover: string = 'Tenant Gross Rent <= Maximum Rent';
  public OverallTenEligiblePopover: string = 'Tenant Income Qualified & Tenant Rent Qualified.';
  public rentSubsidyPopover: string = 'If none exists please add a "0".';
  public numOfSeniorsPopover: string = '55+';
  public exemptUnitCommentPopover: string = 'Add title of the person occupying the exempt unit.';
  public tenantTransferPopover: string = 'After adding the new unit #, date of transfer, new building # (if applicable) and saving the form, the current unit will become vacant and a new unit will be added with all previous information. If any information has changed with this transfer, it will need to be updated in the new unit.';
  public datePopover: string = 'Please use the calendar to enter date or enter date with 4 digit year';
  public dateOfTransferPopover: string = 'Please use the calendar to enter the date, the picked date will automatically populate into the Move-Out Date.';
  public nonQualifiedOccupiedPopover: string = 'Non-Qualified Occupied is a household currently occupying a unit without a current LIHTC certification completed.';
  public nonOptionalFeePopover: string = 'Charges to tenant for services that are not optional. If none, enter 0';
  public optionalFeePopover: string = 'Optional charges tenant is paying. If none, enter 0';
  public optionalFeeTypePopover: string = 'If tenant is paying for optional charges, please list type.  Ex: Garage, Washer/Dryer, Parking, Pet Rent, etc.*Only required if there are optinal fees. *Only required if there are optinal fees.';
  public notExemptUnit: boolean = true;
  public exemptUnit: boolean = false;
  public marketUnit: boolean = false;
  public tenantTransferUnit: boolean = false;
  public previouslyTaxCreditQualifiedCheck: boolean = false;
  public showExemptComment: boolean = false;
  public isAdmin: boolean = false;
  public isSpecialAdmin: boolean = false;
  public complianceAssgiendTo: any[] = [];
  public showTransferTenant: boolean = false;
  public minEndDate: {year: number, month:number, day: number} | null = null
  public fileRatingTypes: any[] = [];
  public seniorTypes: any[] = [];
  public accessibleTypes: any[] = [];
  public unitList: any[] = [];
  // @ViewChild('myModal') myModal : any;
  public reason: string = '';

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private unitDetailsService: UnitDetailsService,
    private dialogsService: DialogsService,
    public propertyService: PropertyService,
    public userService: UserService,
    private datePipe: DatePipe) {
    
    this.formExemptTop = this.fb.group({
      exemptUnit: [false, []],
      marketUnit: [false, []],
      tenantTransfer: [false, []],
      vacantUnit: [false, []],
      nonQualifiedOccupied: [false, []],
      exemptUnitComment: ['', []],
      internalComment: ['', []]
    });
    this.form = this.fb.group({
    //unitID: [null, []],
    buildingID: [null, Validators.required],
    unitNumber: ['', Validators.required],
    firstYearTenantName: ['', Validators.required],
    moveInDate: ['', Validators.compose([Validators.required, dateValidator2])],
    certEffectDate: ['', Validators.compose([Validators.required, dateValidator2])],
    tempDateVacated: [null, dateValidator2],
    tempDateReturned: [null, dateValidator2],
    moveOutDate: [null, dateValidator2],
    numOfBdrms: ['', Validators.required],
    squareFeet: ['', Validators.required],
    unitSetAside: ['', Validators.compose([Validators.required, Validators.min(1)])],
    totalTenants: [0, Validators.required],
    numOfMinors: [0, Validators.required],
    numOfSeniors: [0, Validators.required],
    numOfVeterans: [0, Validators.required],
    numOfStudents: [0, Validators.required],
    allFullTimeStudents: [false, Validators.required],
    grossIncome: ['', Validators.required],
    moveInIncomeLimits: ['', Validators.required],
    unitRent: ['', Validators.required],
    rentSubsidy: ['', Validators.required],
    utilityAllowance: ['', Validators.required],
    maximumRent: ['', Validators.required],
    previouslyTaxCreditQualified: [false, []],
    unitFileReceived: [false, []],
    fileRatingID: [null, []],
    unitComments: ['', []],
    accessibleTypeID: [null, []],
    seniorTypeID: [null, []],
    historicalUnitData: [false, []],
    nonOptionalFee: ['', Validators.compose([Validators.required, Validators.pattern('(\.?)([0-9]*[0-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[0-9][0-9]*)(\.?)')])],
    optionalFee: ['', Validators.compose([Validators.required, Validators.pattern('(\.?)([0-9]*[0-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[0-9][0-9]*)(\.?)')])],
    optionalFeeType: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
    unitCorrectionReceived: [false, []],
    qualificationOverride: ['', []],
    createdDate: ['', []],
    updatedDate: ['', []],
    createdByName: ['', []],
    updatedByName: ['', []]
    });
    this.formExempt = this.fb.group({
      buildingID: [null, Validators.required],
      unitNumber: ['', Validators.required],
      firstYearTenantName: ['', Validators.required],
      moveInDate: ['', Validators.compose([Validators.required, dateValidator2])],
      moveOutDate: [null, []],
      numOfBdrms: [null, Validators.required],
      squareFeet: [null, Validators.required],
      nonOptionalFee: ['', Validators.compose([Validators.required, Validators.pattern('(\.?)([0-9]*[0-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[0-9][0-9]*)(\.?)')])],
      optionalFee: ['', Validators.compose([Validators.required, Validators.pattern('(\.?)([0-9]*[0-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[0-9][0-9]*)(\.?)')])],
      optionalFeeType: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      qualificationOverride: ['', []],
      createdDate: ['', []],
      updatedDate: ['', []],
      createdByName: ['', []],
      updatedByName: ['', []]
    });
    this.formMarket = this.fb.group({
      buildingID: [null, Validators.required],
      unitNumber: ['', Validators.required],
      firstYearTenantName: ['', Validators.required],
      moveInDate: ['', Validators.compose([Validators.required, dateValidator2])],
      moveOutDate: [null, []],
      numOfBdrms: [null, Validators.required],
      squareFeet: [null, Validators.required],
      nonOptionalFee: ['', Validators.compose([Validators.required, Validators.pattern('(\.?)([0-9]*[0-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[0-9][0-9]*)(\.?)')])],
      optionalFee: ['', Validators.compose([Validators.required, Validators.pattern('(\.?)([0-9]*[0-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[0-9][0-9]*)(\.?)')])],
      optionalFeeType: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      marketUnitComment: ['', []],
      qualificationOverride: ['', []],
      createdDate: ['', []],
      updatedDate: ['', []],
      createdByName: ['', []],
      updatedByName: ['', []]
    });
    this.form3 = this.fb.group({
      unitLocked: [false, []],
    })
    this.formPreviouslyTaxCreditQualified = this.fb.group({
      //BFS: 1.06 7.20.2020 - in this form, the date picker wont show the date unless you add something in the unitDeatils.component.ts as well as the unitDetail.service.ts.
      previousCertEffectDate: [null, dateValidator2],
      previousTotalTenants: [null, []],
      previousGrossIncome: [null, []],
      previousMoveInIncomeLimits: [null, []],
      previousUnitRent: [null, []],
      previousRentSubsidy: [null, []],
      previousUtilityAllowance: [null, []],
      previousMaximumRent: [null, []],
    });
    this.formTenantTransfer = this.fb.group({
      buildingID: [null, Validators.required],
      newUnitNumber: ['', Validators.required],
      dateOfTransfer: ['', Validators.compose([Validators.required, dateValidator2])],
      unitNumber: ['', Validators.required],
      firstYearTenantName: ['', Validators.required],
      moveInDate: ['', Validators.compose([Validators.required, dateValidator2])],
      certEffectDate: ['', Validators.compose([Validators.required, dateValidator2])],
      tempDateVacated: [null, dateValidator2],
      tempDateReturned: [null, dateValidator2],
      moveOutDate: [null, dateValidator2],
      numOfBdrms: ['', Validators.required],
      squareFeet: ['', Validators.required],
      unitSetAside: ['', Validators.compose([Validators.required, Validators.min(1)])],
      totalTenants: [0, Validators.required],
      numOfMinors: [0, Validators.required],
      numOfSeniors: [0, Validators.required],
      numOfVeterans: [0, Validators.required],
      numOfStudents: [0, Validators.required],
      allFullTimeStudents: [0, Validators.required],
      grossIncome: ['', Validators.required],
      moveInIncomeLimits: ['', Validators.required],
      unitRent: ['', Validators.required],
      rentSubsidy: ['', Validators.required],
      utilityAllowance: ['', Validators.required],
      maximumRent: ['', Validators.required],
      previouslyTaxCreditQualified: [false, []],
      unitFileReceived: [false, []],
      fileRatingID: [null, []],
      unitComments: ['', []],
      accessibleTypeID: [null, []],
      seniorTypeID: [null, []],
      historicalUnitData: [false, []],
      nonOptionalFee: ['', Validators.compose([Validators.required, Validators.pattern('(\.?)([0-9]*[0-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[0-9][0-9]*)(\.?)')])],
      optionalFee: ['', Validators.compose([Validators.required, Validators.pattern('(\.?)([0-9]*[0-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[0-9][0-9]*)(\.?)')])],
      optionalFeeType: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      unitCorrectionReceived: [false, []],
      qualificationOverride: ['', []],
      createdDate: ['', []],
      updatedDate: ['', []],
      createdByName: ['', []],
      updatedByName: ['', []]
    });
    this.formVacant = this.fb.group({
      buildingID: [null, Validators.required],
      unitNumber: ['', Validators.required],
      numOfBdrms: [null, Validators.required],
      squareFeet: [null, Validators.required],
      vacantUnitComment: ['', []],
      qualificationOverride: ['', []],
      createdDate: ['', []],
      updatedDate: ['', []],
      createdByName: ['', []],
      updatedByName: ['', []]
    });
    this.formNonQualifiedOccupied = this.fb.group({
      buildingID: [null, Validators.required],
      unitNumber: ['', Validators.required],
      numOfBdrms: [null, Validators.required],
      squareFeet: [null, Validators.required],
      nonQualifiedOccupiedComment: ['', []],
      qualificationOverride: ['', []],
      createdDate: ['', []],
      updatedDate: ['', []],
      createdByName: ['', []],
      updatedByName: ['', []]
    });
    this.propertyList = [];
  }

  ngOnInit() {
    this.getFileRatingTypes();
    this.getSeniorTypes();
    this.getAccessibleTypes();
    var day = this.datePipe.transform(this.unit.moveInDate, 'dd')
    if(day){
      var dayInt = parseInt(day);
      if(typeof this.unit.moveInDate === "object"){
        this.minEndDate = {year: this.unit.moveInDate.getFullYear(),
                           month: this.unit.moveInDate.getMonth() + 1, 
                           day: dayInt}
      }
    }

    this.propertyService.getAllComplianceAssignedTo()
    .subscribe((data) => {
      this.complianceAssgiendTo = data;
    });
    this.isAdd = !(this.unit && this.unit.unitID);
    this.dialogTitle = this.isAdd
      ? 'Add Unit'
      : 'Update Unit';
      
    this.formVacant.patchValue(this.unit);
    this.formExemptTop.patchValue(this.unit);
    this.form3.patchValue(this.unit);
    if(this.unit.allFullTimeStudents == null){

      this.unit.allFullTimeStudents = false;
    }
    this.form.patchValue(this.unit);

    // Joe: This is to setup the view to use the correct form and to setup the save b
    if (this.formExemptTop.controls['exemptUnit'].value === true) {
      this.formExempt.patchValue(this.unit);
      this.formType = 'Exempt';
      if(this.formExempt.controls['optionalFee'].value === 0 || this.formExempt.controls['optionalFee'].value === '' || this.formExempt.controls['optionalFee'].value === null){
        this.formExempt.controls['optionalFeeType'].disable();
      }
    } else if (this.formExemptTop.controls['marketUnit'].value === true){
      this.formMarket.patchValue(this.unit);
      this.formType = 'Market';
      if(this.formMarket.controls['optionalFee'].value === 0 || this.formMarket.controls['optionalFee'].value === '' || this.formMarket.controls['optionalFee'].value === null){
        this.formMarket.controls['optionalFeeType'].disable();
      }
    } else if(this.formExemptTop.controls['tenantTransfer'].value === true){
      this.formTenantTransfer.patchValue(this.unit)
      this.formType = "Transfer";
      if(this.formTenantTransfer.controls['previouslyTaxCreditQualified'].value === true){
        this.formPreviouslyTaxCreditQualified.patchValue(this.unit);
        this.formType = 'TransferPrevQual';
      }
      if(this.formTenantTransfer.controls['optionalFee'].value === 0 || this.formTenantTransfer.controls['optionalFee'].value === '' || this.formTenantTransfer.controls['optionalFee'].value === null){
        this.formTenantTransfer.controls['optionalFeeType'].disable();
      }
    } else if(this.formExemptTop.controls['vacantUnit'].value === true){
      this.formVacant.patchValue(this.unit);
      this.formType = 'Vacant';
    } else if (this.formExemptTop.controls['nonQualifiedOccupied'].value === true){
      this.formNonQualifiedOccupied.patchValue(this.unit);
      this.formType = 'NonQualifiedOccupied';
    }else {
      this.form.patchValue(this.unit);
      this.formType = 'Normal';
      if(this.form.controls['previouslyTaxCreditQualified'].value === true){
        this.formPreviouslyTaxCreditQualified.patchValue(this.unit);
        this.formType = 'NormalPrevQual';
      }
      if(this.form.controls['optionalFee'].value === 0 || this.form.controls['optionalFee'].value === '' || this.form.controls['optionalFee'].value === null){
        this.form.controls['optionalFeeType'].disable();
      }
    }

    //BFS: 1.10.1 - 3.10.21 if 'Optional Fee' is '' || 0 then disable 'Optional Fee Type' for form && formTenantTransfer
    if((this.form.controls['optionalFee'].value === 0 || this.form.controls['optionalFee'].value === '' || this.form.controls['optionalFee'].value === null) || this.form3.controls['unitLocked'].value){
      this.form.controls['optionalFeeType'].disable();
    }
    if(this.formTenantTransfer.controls['optionalFee'].value === 0 || this.formTenantTransfer.controls['optionalFee'].value === ''){
      this.formTenantTransfer.controls['optionalFeeType'].disable();
    }

    if(this.formExempt.controls['optionalFee'].value === 0 || this.formExempt.controls['optionalFee'].value === ''){
      this.formExempt.controls['optionalFeeType'].disable();
    }

    if(this.formMarket.controls['optionalFee'].value === 0 || this.formMarket.controls['optionalFee'].value === ''){
      this.formMarket.controls['optionalFeeType'].disable();
    }

    this.isAdmin = this.authService.isAuthUserInRole("Admin");
    this.isSpecialAdmin = this.authService.isAuthUserInRole("Special");
    if(this.isSpecialAdmin === true){
      this.form.controls['qualificationOverride'].enable();
      this.form.controls['internalComment'].enable();
    } else {
      this.form.controls['qualificationOverride'].disable();
      this.form.controls['internalComment'].disable();
    }
  }

  ngAfterViewInit(){
    this._updateCalculations();
    if(this.showTransferTenant){
      this._updateTenantTransferCalculations();
    }
  }

  isFormTypeExempt(): boolean {
    return this.formType == 'Exempt' ;
  } 

  isFormTypeMarket(): boolean {
    return this.formType == 'Market' ;
  } 

  isFormTypeVacant(): boolean {
    return this.formType == 'Vacant' ;
  } 

  isFormTypeNonQualifiedOccupied(): boolean {
    return this.formType == 'NonQualifiedOccupied' ;
  } 

  isFormTypeNormal(): boolean {
    return this.formType == 'Normal' ;
  } 

  isFormTypeNormalPrevQual(): boolean {
    return this.formType == 'NormalPrevQual' ;
  } 

  public _updateMoveOutLimit(){
    var day = null;
    var month = null;
    var year = null;
    switch(this.formType){
      case 'Normal':
      case 'NormalPrevQual':{
        // removes the move out date if the user changes the move in date to be after the move out date
        if(this.form.controls['moveInDate'].value && this.form.controls['moveOutDate'].value){
          if(this.form.controls['moveInDate'].value.setHours(0,0,0,0) >= this.form.controls['moveOutDate'].value.setHours(0,0,0,0)){
            this.form.controls['moveOutDate'].setValue(null);
          }
        }
        day = this.datePipe.transform(this.form.controls['moveInDate'].value, 'dd');
        month = this.form.controls['moveInDate'].value.getMonth() + 1;
        year = this.form.controls['moveInDate'].value.getFullYear();
      }
        break;
      case "Transfer":
      case "TransferPrevQual":{
        // removes the move out date if the user changes the move in date to be after the move out date
        if(this.formTenantTransfer.controls['moveInDate'].value && this.formTenantTransfer.controls['moveOutDate'].value){
          if(this.formTenantTransfer.controls['moveInDate'].value.setHours(0,0,0,0) >= this.formTenantTransfer.controls['moveOutDate'].value.setHours(0,0,0,0)){
            this.formTenantTransfer.controls['moveOutDate'].setValue(null);
          }
        }
        day = this.datePipe.transform(this.formTenantTransfer.controls['moveInDate'].value, 'dd');
        month = this.formTenantTransfer.controls['moveInDate'].value.getMonth() + 1;
        year = this.formTenantTransfer.controls['moveInDate'].value.getFullYear();
      }
        break;
      case "Market":{
        // removes the move out date if the user changes the move in date to be after the move out date
        if(this.formMarket.controls['moveInDate'].value && this.formMarket.controls['moveOutDate'].value){
          if(this.formMarket.controls['moveInDate'].value.setHours(0,0,0,0) >= this.formMarket.controls['moveOutDate'].value.setHours(0,0,0,0)){
            this.formMarket.controls['moveOutDate'].setValue(null);
          }
        }
        day = this.datePipe.transform(this.formMarket.controls['moveInDate'].value, 'dd');
        month = this.formMarket.controls['moveInDate'].value.getMonth() + 1;
        year = this.formMarket.controls['moveInDate'].value.getFullYear();
      }
        break;
      case "Exempt":{
        // removes the move out date if the user changes the move in date to be after the move out date
        if(this.formExempt.controls['moveInDate'].value && this.formExempt.controls['moveOutDate'].value){
          if(this.formExempt.controls['moveInDate'].value.setHours(0,0,0,0) >= this.formExempt.controls['moveOutDate'].value.setHours(0,0,0,0)){
            this.formExempt.controls['moveOutDate'].setValue(null);
          }
        }
        day = this.datePipe.transform(this.formExempt.controls['moveInDate'].value, 'dd');
        month = this.formExempt.controls['moveInDate'].value.getMonth() + 1;
        year = this.formExempt.controls['moveInDate'].value.getFullYear();
      }
        break;
        default:{
          console.log("unknown form type selected");
        }
    }
    if(day && month && year){
      var dayInt = parseInt(day);
        this.minEndDate = {year: year,
                           month: month, 
                           day: dayInt}
    } else {
      this.minEndDate = null;
    }
  }

  public _updateCalculations() {
    if(this.formExemptTop.controls['exemptUnit'].value === false && this.formExemptTop.controls['marketUnit'].value === false ){ //&& this.formExemptTop.value.marketUnit === false && this.formExemptTop.value.exemptUnit === false
      var tenantIncomeQualified = this.form.controls['grossIncome'].value < this.form.controls['moveInIncomeLimits'].value ? 'Yes' : 'No';
      //var tenantPayment = this.form.controls['unitRent'].value -  this.form.controls['rentSubsidy'].value;
      var tenantPayment = this.form.controls['unitRent'].value;
      var tenantGrossRent = tenantPayment +  this.form.controls['utilityAllowance'].value + this.form.controls['nonOptionalFee'].value;
      var tenantRentQualified = tenantGrossRent <= this.form.controls['maximumRent'].value ? 'Yes' : 'No';
      var overallTenantEligible = tenantIncomeQualified === 'Yes' && tenantRentQualified === 'Yes' ? 'Yes' : 'No';
      (document.getElementById('tenantIncomeQualified') as HTMLInputElement).value = tenantIncomeQualified;
      (document.getElementById('tenantGrossRent') as HTMLInputElement).value = tenantGrossRent;
      (document.getElementById('tenantRentQualified') as HTMLInputElement).value = tenantRentQualified;
      (document.getElementById('overallTenantEligible') as HTMLInputElement).value = overallTenantEligible;
    }

    if(this.form.controls['previouslyTaxCreditQualified'].value === true){
      this._prevTaxCredQualifiedCheckedCalculations();
      // var tenantIncomeQualifiedPrevious = this.formPreviouslyTaxCreditQualified.controls['previousGrossIncome'].value < this.formPreviouslyTaxCreditQualified.controls['previousMoveInIncomeLimits'].value ? 'Yes' : 'No';
      // //var tenantPaymentPrevious = this.formPreviouslyTaxCreditQualified.controls['previousUnitRent'].value -  this.formPreviouslyTaxCreditQualified.controls['previousRentSubsidy'].value;
      // var tenantPaymentPrevious = this.formPreviouslyTaxCreditQualified.controls['previousUnitRent'].value;
      // var tenantGrossRentPrevious = tenantPaymentPrevious +  this.formPreviouslyTaxCreditQualified.controls['previousUtilityAllowance'].value;
      // var tenantRentQualifiedPrevious = tenantGrossRentPrevious <= this.formPreviouslyTaxCreditQualified.controls['previousMaximumRent'].value ? 'Yes' : 'No';
      // var overallTenantEligiblePrevious = tenantIncomeQualifiedPrevious === 'Yes' && tenantRentQualifiedPrevious === 'Yes' ? 'Yes' : 'No';
      // (document.getElementById('tenantIncomeQualifiedPrevious') as HTMLInputElement).value = tenantIncomeQualifiedPrevious;
      // (document.getElementById('tenantGrossRentPrevious') as HTMLInputElement).value = tenantGrossRentPrevious;
      // (document.getElementById('tenantRentQualifiedPrevious') as HTMLInputElement).value = tenantRentQualifiedPrevious;
      // (document.getElementById('overallTenantEligiblePrevious') as HTMLInputElement).value = overallTenantEligiblePrevious;
      }
  }

  public _updateTenantTransferCalculations() {
    if(this.formExemptTop.controls['exemptUnit'].value === false && this.formExemptTop.controls['marketUnit'].value === false && this.formExemptTop.value.tenantTransfer === true){ //&& this.formExemptTop.value.marketUnit === false && this.formExemptTop.value.exemptUnit === false
      var tenantIncomeQualified = this.formTenantTransfer.controls['grossIncome'].value < this.formTenantTransfer.controls['moveInIncomeLimits'].value ? 'Yes' : 'No';
      //var tenantPayment = this.form.controls['unitRent'].value -  this.form.controls['rentSubsidy'].value;
      var tenantPayment = this.formTenantTransfer.controls['unitRent'].value;
      var tenantGrossRent = tenantPayment +  this.formTenantTransfer.controls['utilityAllowance'].value + this.formTenantTransfer.controls['nonOptionalFee'].value;
      var tenantRentQualified = tenantGrossRent <= this.formTenantTransfer.controls['maximumRent'].value ? 'Yes' : 'No';
      var overallTenantEligible = tenantIncomeQualified === 'Yes' && tenantRentQualified === 'Yes' ? 'Yes' : 'No';
      (document.getElementById('tenantIncomeQualified') as HTMLInputElement).value = tenantIncomeQualified;
      (document.getElementById('tenantGrossRent') as HTMLInputElement).value = tenantGrossRent;
      (document.getElementById('tenantRentQualified') as HTMLInputElement).value = tenantRentQualified;
      (document.getElementById('overallTenantEligible') as HTMLInputElement).value = overallTenantEligible;
    }

    if(this.form.controls['previouslyTaxCreditQualified'].value === true){
      this._prevTaxCredQualifiedCheckedCalculations();
      // var tenantIncomeQualifiedPrevious = this.formPreviouslyTaxCreditQualified.controls['previousGrossIncome'].value < this.formPreviouslyTaxCreditQualified.controls['previousMoveInIncomeLimits'].value ? 'Yes' : 'No';
      // //var tenantPaymentPrevious = this.formPreviouslyTaxCreditQualified.controls['previousUnitRent'].value -  this.formPreviouslyTaxCreditQualified.controls['previousRentSubsidy'].value;
      // var tenantPaymentPrevious = this.formPreviouslyTaxCreditQualified.controls['previousUnitRent'].value;
      // var tenantGrossRentPrevious = tenantPaymentPrevious +  this.formPreviouslyTaxCreditQualified.controls['previousUtilityAllowance'].value;
      // var tenantRentQualifiedPrevious = tenantGrossRentPrevious <= this.formPreviouslyTaxCreditQualified.controls['previousMaximumRent'].value ? 'Yes' : 'No';
      // var overallTenantEligiblePrevious = tenantIncomeQualifiedPrevious === 'Yes' && tenantRentQualifiedPrevious === 'Yes' ? 'Yes' : 'No';
      // (document.getElementById('tenantIncomeQualifiedPrevious') as HTMLInputElement).value = tenantIncomeQualifiedPrevious;
      // (document.getElementById('tenantGrossRentPrevious') as HTMLInputElement).value = tenantGrossRentPrevious;
      // (document.getElementById('tenantRentQualifiedPrevious') as HTMLInputElement).value = tenantRentQualifiedPrevious;
      // (document.getElementById('overallTenantEligiblePrevious') as HTMLInputElement).value = overallTenantEligiblePrevious;
      }
  }

  public openNotifyReason(){
    this.openReasonDialog(this.reason)
    .then((res: any) => {
      this.email(res.value.reason);
    }, () => {

    });
  }

  private openReasonDialog(reason: string) {
    const modalRef = this.modalService.open(NotifyReasonDialogComponent,
      {
        backdrop: true,
        beforeDismiss: ():any => {
        if (modalRef.componentInstance.form.dirty) {
          return this.showConfirmationMessage()
        }
        return true;
      }
    });
    modalRef.componentInstance.reason = reason;
    return modalRef.result;
  }

  showConfirmationMessage() {
    const title = 'Confirmation';
    const message = 'This form will not be saved, are you sure you want to exit this form?';
    return this.dialogsService.confirmation(title, message)
  }

  public closeReason(){
    this.activeModal.dismiss();
  }

  public email(reason: any){
    var data = {
      name: 'Compliance',
      toAddress: '',
      unit: this.form.controls['unitNumber'].value,
      building: this._buildingFilter(this.form.controls['buildingID'].value)[0].buildingNumber,
      property: this.property.name,
      user: '',
      emailType: 'Unit',
      reason: reason
    }

    //BFS: 11/29/2022 - 01.11 - taking this out and replaceing it with  compliance@wncinc.com
    // if(this.property.assignedTo){
    //   var splitName = this.property.assignedTo.split(' ');
    //    data.name = splitName[0];
    // } else{
    //   data.name = 'none';
    // }
    
    ////data.toAddress = (splitName[0].charAt(0) + splitName[1] + '@wncinc.com');
    // data.toAddress = this.property.emailAddress;
    var user = this.authService.getAuthUser();
    if(user){
      if(user.email){
        data.user = user.email;
      }
    }
    this.unitDetailsService.notifyCompliance(data).subscribe((_res) => {
      const title = 'Email sent';
      const message = `The compliance coordinator has been notified and will respond at their earliest opportunity.`; //${ property.name }?
      this.dialogsService.alert(title, message)
        .then(() => {

        }, () => { });

    });
  }

  preventInput(event: any){
    event.preventDefault();
  }

  //BFS: 1.10.8 - this save is pretty complicating. separating it all out so its easier to read.
  //the save will if its an admin or just a user
  //then it will go into all of the different forms
  //when everything goes well, the backend will take care of either updating, or adding a new vacant unit or updating a vacant unit when people move into them and such.
  //this made me cry couple of times
  save() {
    if(this.isAdmin === true){
      this.adminSave();
    } else {
      this.nonAdminSave();
    }
  }

  //#region save for admin and non admins
  public adminSave(){
    if(this.formType == 'Normal' || this.formType == 'NormalPrevQual'){
      if (this.form.invalid) {
        this.isFailedForm = true;
      } else {
        this.adminNormalNormalPrevQualAndIncludingHistorical();
      }
    } else if (this.formType == 'Exempt') {
      if (this.formExempt.invalid) {
        this.isFailedForm = true;
      } else {
        this.adminExempt();
      }
    } else if (this.formType == 'Transfer' || this.formType == 'TransferPrevQual') {
      if (this.formTenantTransfer.invalid) {
        this.isFailedForm = true;
      } else {
        this.adminTransferTransferPrevQual();
      }
    } else if (this.formType == 'Vacant'){
      if (this.formVacant.invalid) {
        this.isFailedForm = true;
      } else {
        this.adminVacant();
      }
    } else if (this.formType == 'NonQualifiedOccupied'){
      if (this.formNonQualifiedOccupied.invalid) {
        this.isFailedForm = true;
      } else {
        this.adminNonQualifiedOccupied();
      }
    } else {
      if (this.formMarket.invalid) {
        this.isFailedForm = true;
      } else {
        this.adminMarket();
      }
    }
  }

  public adminNormalNormalPrevQualAndIncludingHistorical(){
    const isHistorical = this.form.value.historicalUnitData;
    //so this is for when they r trying to update a unit that somebody has moved out of and is not just historical data.
    //this is a VERY RARE occation. once somebody moves out, that is it, there is no reason for them to edit this data EVER but they want the ability to (RARELY HAPPENS)
    if(isHistorical === true){
      const title = 'Edit Historical Unit Data';
      const message = `This unit is historical data. Once you save, this historical data and the current data will not match. Are you sure you want to change this information?`;
      this.dialogsService.confirmation(title, message)
        .then(() => {
          const formValue = this.form.getRawValue();
          const formvalue2 = this.form3.getRawValue();
          const formvalue3 = this.formExemptTop.getRawValue();
          const formvalue4 = this.formPreviouslyTaxCreditQualified.getRawValue();
        
          let replacedData: any = {};
  
          Object.keys(formValue).forEach((key: string) => {
            formValue[key] == null ? replacedData[key] = '' : replacedData[key] = formValue[key]
          })
          Object.keys(formvalue2).forEach((key: string) => {
            formvalue2[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue2[key];
          })
          Object.keys(formvalue3).forEach((key: string) => {
            formvalue3[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue3[key];
          })
          Object.keys(formvalue4).forEach((key: string) => {
            formvalue4[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue4[key];
          })
  
          const report = Object.assign({}, this.unit, replacedData);
          this.activeModal.close(report);
          }, () => { });

    } else {
      //now its not historical, meaning its just a regular unit or a prev qualified unit
      if(this.form.value.moveOutDate){
        this.form.controls['historicalUnitData'].setValue(true);
      }
      const formValue = this.form.getRawValue();
      const formvalue2 = this.form3.getRawValue();
      const formvalue3 = this.formExemptTop.getRawValue();
      const formvalue4 = this.formPreviouslyTaxCreditQualified.getRawValue();
    
      let replacedData: any = {};

      Object.keys(formValue).forEach((key: string) => {
        formValue[key] == null ? replacedData[key] = '' : replacedData[key] = formValue[key]
      })
      Object.keys(formvalue2).forEach((key: string) => {
        formvalue2[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue2[key];
      })
      Object.keys(formvalue3).forEach((key: string) => {
        formvalue3[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue3[key];
      })
      Object.keys(formvalue4).forEach((key: string) => {
        formvalue4[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue4[key];
      })

      const report = Object.assign({}, this.unit, replacedData);
      this.activeModal.close(report);
    }

  }

  public adminExempt(){
    const formValue = this.formExempt.getRawValue();
    const formvalue2 = this.form3.getRawValue();
    const formvalue3 = this.formExemptTop.getRawValue();
    const formvalue4 = this.formPreviouslyTaxCreditQualified.getRawValue();
    const formvalue5 = this.formTenantTransfer.getRawValue();
    let replacedData: any = {};

    Object.keys(formvalue2).forEach((key: string) => {
      formvalue2[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue2[key];
    })
    Object.keys(formvalue3).forEach((key: string) => {
      formvalue3[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue3[key];
    })
    Object.keys(formvalue4).forEach((key: string) => {
      formvalue4[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue4[key];
    })
    Object.keys(formvalue5).forEach((key: string) => {
      formvalue5[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue5[key];
    })
    Object.keys(formValue).forEach((key: string) => {
      formValue[key] == null ? replacedData[key] = '' : replacedData[key] = formValue[key]
    })

    const report = Object.assign({}, this.unit, replacedData);
    this.activeModal.close(report);
  }

  public adminTransferTransferPrevQual(){
        // //NOW IN THE BACK, IT AUTOMATICALLY CREATES A VACANT UNIT WITH THE CURRENT UNIT NUMBER WHEN SOMEBODY MOVES OUT OR TRANSFERS
        // //NOW ALL YOU HAVE TO DO IS CHECK IF IN THE LIST, THERE IS A UNIT WITH THE NEW UNIT NUMBER and the building id YOU ARE TRYING TO TRANSFER INTO AND IF THAT IS VACANT OR NOT!!!!!!!*************
        // //bfs: its looking for a unit in the list that has the same unit number as the NEW unit number that the tenant is transfering to 
        // //AND if the unit with the same unit # DOES have a move out date (vacant), then let them insert one with that NEW unit #. if the move out date 
        // //does NOT exist then dont let them insert one.
        
        //so in the list of units, if there is a unit with the same unit# that they are trying to move into, and in the same building, then the unit exists (true) *then dont let them
        const unitExists = this.unitList.some(el => el.unitNumber === this.formTenantTransfer.value.newUnitNumber && el.buildingID === this.formTenantTransfer.value.buildingID);
        //if in the list of units, if there is a unit with the same unit# that they are trying to move into, and in the same bulding, BUT IS VACANT!!!! (true) *then let them
        const unitExists1 = this.unitList.some(el => el.unitNumber === this.formTenantTransfer.value.newUnitNumber && el.buildingID === this.formTenantTransfer.value.buildingID && el.vacantUnit === true);
        
        const isHistorical = this.formTenantTransfer.value.historicalUnitData;
        if((unitExists === false || unitExists1 === true) || isHistorical === true ){

          if(isHistorical === false){
            if(this.formTenantTransfer.value.moveOutDate && this.formTenantTransfer.value.newUnitNumber && this.formTenantTransfer.value.dateOfTransfer){
              this.formTenantTransfer.controls['historicalUnitData'].setValue(true);
            }
            
            const formValue = this.formExempt.getRawValue();
            const formvalue2 = this.form3.getRawValue();
            const formvalue3 = this.formExemptTop.getRawValue();
            const formvalue4 = this.formPreviouslyTaxCreditQualified.getRawValue();
            const formvalue5 = this.formTenantTransfer.getRawValue();
            let replacedData: any = {};
  
            Object.keys(formValue).forEach((key: string) => {
              formValue[key] == null ? replacedData[key] = '' : replacedData[key] = formValue[key]
            })
            Object.keys(formvalue2).forEach((key: string) => {
              formvalue2[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue2[key];
            })
            Object.keys(formvalue3).forEach((key: string) => {
              formvalue3[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue3[key];
            })
            Object.keys(formvalue4).forEach((key: string) => {
              formvalue4[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue4[key];
            })
            Object.keys(formvalue5).forEach((key: string) => {
              formvalue5[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue5[key];
            })
  
            const report = Object.assign({}, this.unit, replacedData);
            this.activeModal.close(report);
          } else {
            //BFS: 1.10 - IF ITS A HISTORICAL UNIT, IT'LL JUST UPDATE AND NOT CREATE ANY NEW VACANT UNITS IN THE BACK
            const title = 'Edit Historical Unit Data';
            const message = `This unit is historical data. Once you save, this historical data and the current data will not match. Are you sure you want to change the information?`;
            this.dialogsService.confirmation(title, message)
              .then(() => {
                const formValue = this.formExempt.getRawValue();
                const formvalue2 = this.form3.getRawValue();
                const formvalue3 = this.formExemptTop.getRawValue();
                const formvalue4 = this.formPreviouslyTaxCreditQualified.getRawValue();
                const formvalue5 = this.formTenantTransfer.getRawValue();
                let replacedData: any = {};
      
                Object.keys(formValue).forEach((key: string) => {
                  formValue[key] == null ? replacedData[key] = '' : replacedData[key] = formValue[key]
                })
                Object.keys(formvalue2).forEach((key: string) => {
                  formvalue2[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue2[key];
                })
                Object.keys(formvalue3).forEach((key: string) => {
                  formvalue3[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue3[key];
                })
                Object.keys(formvalue4).forEach((key: string) => {
                  formvalue4[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue4[key];
                })
                Object.keys(formvalue5).forEach((key: string) => {
                  formvalue5[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue5[key];
                })
                const report = Object.assign({}, this.unit, replacedData);
                this.activeModal.close(report);
              }, () => { });
          }
        } else {
          this.formTenantTransfer.controls['moveOutDate'].setValue(null);
          this.formTenantTransfer.controls['newUnitNumber'].setValue(null);
          this.formTenantTransfer.controls['dateOfTransfer'].setValue(null);
          this.showErrorMessage('There is a unit with the same unit number that is currently occupied. Please select a different unit number');
        }
  }

  public adminVacant(){
    const formValue = this.formVacant.getRawValue();
    const formvalue2 = this.form3.getRawValue();
    const formvalue3 = this.formExemptTop.getRawValue();
    //const formvalue4 = this.formPreviouslyTaxCreditQualified.getRawValue();
    const formvalue5 = this.formTenantTransfer.getRawValue();
    let replacedData: any = {};

    Object.keys(formvalue2).forEach((key: string) => {
      formvalue2[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue2[key];
    })
    Object.keys(formvalue3).forEach((key: string) => {
      formvalue3[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue3[key];
    })
    // Object.keys(formvalue4).forEach((key: string) => {
    //   formvalue4[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue4[key];
    // })
    Object.keys(formvalue5).forEach((key: string) => {
      formvalue5[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue5[key];
    })
    Object.keys(formValue).forEach((key: string) => {
      formValue[key] == null ? replacedData[key] = '' : replacedData[key] = formValue[key]
    })

    const report = Object.assign({}, this.unit, replacedData);
    this.activeModal.close(report);
  }

  public adminNonQualifiedOccupied(){
    const formValue = this.formNonQualifiedOccupied.getRawValue();
    const formvalue2 = this.form3.getRawValue();
    const formvalue3 = this.formExemptTop.getRawValue();
    const formvalue4 = this.formPreviouslyTaxCreditQualified.getRawValue();
    const formvalue5 = this.formTenantTransfer.getRawValue();
    let replacedData: any = {};

    Object.keys(formvalue2).forEach((key: string) => {
      formvalue2[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue2[key];
    })
    Object.keys(formvalue3).forEach((key: string) => {
      formvalue3[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue3[key];
    })
    Object.keys(formvalue4).forEach((key: string) => {
      formvalue4[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue4[key];
    })
    Object.keys(formvalue5).forEach((key: string) => {
      formvalue5[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue5[key];
    })
    Object.keys(formValue).forEach((key: string) => {
      formValue[key] == null ? replacedData[key] = '' : replacedData[key] = formValue[key]
    })

    const report = Object.assign({}, this.unit, replacedData);
    this.activeModal.close(report);
  }

  public adminMarket(){
    const formValue = this.formMarket.getRawValue();
    const formvalue2 = this.form3.getRawValue();
    const formvalue3 = this.formExemptTop.getRawValue();
    const formvalue4 = this.formPreviouslyTaxCreditQualified.getRawValue();
    const formvalue5 = this.formTenantTransfer.getRawValue();

    let replacedData: any = {};

    Object.keys(formvalue2).forEach((key: string) => {
      formvalue2[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue2[key];
    })
    Object.keys(formvalue3).forEach((key: string) => {
      formvalue3[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue3[key];
    })
    Object.keys(formvalue4).forEach((key: string) => {
      formvalue4[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue4[key];
    })
    Object.keys(formvalue5).forEach((key: string) => {
      formvalue5[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue5[key];
    })
    Object.keys(formValue).forEach((key: string) => {
      formValue[key] == null ? replacedData[key] = '' : replacedData[key] = formValue[key]
    })

    const report = Object.assign({}, this.unit, replacedData);
    this.activeModal.close(report);
  }
  
  //this is the save for non admins. all of the functions following nonAdminSave() are specifically for non admins
  public nonAdminSave(){
    const title = 'Save Unit';
    const message = 'Saving will lock this unit and you will not be able to edit the information unless unlocked by WNC. Please ensure all of the information is correct before saving. Do you want to proceed?'
    this.dialogsService.confirmation(title, message)
    .then(() => {
      if(this.formType == 'Normal' || this.formType == 'NormalPrevQual'){
        if (this.form.invalid) {
          this.isFailedForm = true;
        } else {
          this.nonAdminNormalNormalPrevQual();
        }
      } else if (this.formType == 'Exempt') {
        if (this.formExempt.invalid) {
          this.isFailedForm = true;
        } else {
          this.nonAdminExempt();
        }
      } else if (this.formType == 'Transfer' || this.formType == 'TransferPrevQual') {
        if (this.formTenantTransfer.invalid) {
          this.isFailedForm = true;
        } else {
          this.nonAdminTransferTransferPrevQual();
        }
      } else if (this.formType == 'Vacant'){
        if (this.formVacant.invalid) {
          this.isFailedForm = true;
        } else {
          this.nonAdminVacant();
        }
      } else if (this.formType == 'Market'){
        if (this.formMarket.invalid) {
          this.isFailedForm = true;
        } else {
          this.nonAdminMarket();
        }
      } else {
        if (this.formNonQualifiedOccupied.invalid) {
          this.isFailedForm = true;
        } else {
          this.nonAdminLastElseOrNonQualifiedOccupied();
        }
      }
    }, () => { });
  }

  public nonAdminNormalNormalPrevQual(){
    const formValue = this.form.getRawValue();
    const formvalue2 = this.form3.getRawValue();
    const formvalue3 = this.formExemptTop.getRawValue();
    const formvalue4 = this.formPreviouslyTaxCreditQualified.getRawValue();
  
    let replacedData: any = {};

    Object.keys(formValue).forEach((key: string) => {
      formValue[key] == null ? replacedData[key] = '' : replacedData[key] = formValue[key]
    })
    Object.keys(formvalue2).forEach((key: string) => {
      formvalue2[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue2[key];
    })
    Object.keys(formvalue3).forEach((key: string) => {
      formvalue3[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue3[key];
    })
    Object.keys(formvalue4).forEach((key: string) => {
      formvalue4[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue4[key];
    })

    const unit = Object.assign({}, this.unit, replacedData);
    unit.unitLocked = true;
    this.activeModal.close(unit);
  }

  public nonAdminExempt(){
    const formValue = this.formExempt.getRawValue();
    const formvalue2 = this.form3.getRawValue();
    const formvalue3 = this.formExemptTop.getRawValue();
    const formvalue4 = this.formPreviouslyTaxCreditQualified.getRawValue();
    //const formvalue5 = this.formTenantTransfer.getRawValue();
    let replacedData: any = {};

    Object.keys(formValue).forEach((key: string) => {
      formValue[key] == null ? replacedData[key] = '' : replacedData[key] = formValue[key]
    })
    Object.keys(formvalue2).forEach((key: string) => {
      formvalue2[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue2[key];
    })
    Object.keys(formvalue3).forEach((key: string) => {
      formvalue3[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue3[key];
    })
    Object.keys(formvalue4).forEach((key: string) => {
      formvalue4[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue4[key];
    })
    // Object.keys(formvalue5).forEach((key: string) => {
    //   formvalue5[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue5[key];
    // })
    
    const unit = Object.assign({}, this.unit, replacedData);
    unit.unitLocked = true;
    this.activeModal.close(unit);
  }

  public nonAdminTransferTransferPrevQual(){
    //bfs: its looking for a unit in the list that has the same unit number as the NEW unit number that the tenant is transfering to 
    //AND if the unit with the same unit # DOES have a move out date, then let them insert one with that NEW unit #. if the move out date 
    //does NOT exist then dont let them insert one.
    //so if found === true (the code found a unit that has the same number but HAS a move out date) then let them insert, if found === false then dont let them insert
    //const historicalUnitfound = this.unitList.some(el => el.unitNumber === this.formTenantTransfer.value.newUnitNumber && el.moveOutDate != null) //this most likely is a historical unit now since they either moved out or transferred
    //this one is checking if there is a unit with the newUnitNumber they r trying to add in the list of the units with the same unit number AND without a move out date
    //this can only mean that because there no moveOutDate, this unit is occupied. so if its found ==== true AND found1 === true then dont let them insert
    //if its found === true and found1 === false then its ok
    const occupiedUnitFound = this.unitList.some(el => el.unitNumber === this.formTenantTransfer.value.newUnitNumber && el.buildingID === this.formTenantTransfer.value.buildingID && el.moveOutDate === null && el.vacantUnit === false)//this has the unit number and there is no move out date so this prob is a vacant unit
    //const vacantUnitFound = this.unitList.some(el => el.unitNumber === this.formTenantTransfer.value.newUnitNumber && el.vacantUnit === 1)
    //BFS: 1.081 - so if its both false then that new unit # they are trhing to create does not exist at all, so they can insert that one
    if((occupiedUnitFound === false)){
      const formValue = this.formExempt.getRawValue();
      const formvalue2 = this.form3.getRawValue();
      const formvalue3 = this.formExemptTop.getRawValue();
      const formvalue4 = this.formPreviouslyTaxCreditQualified.getRawValue();
      const formvalue5 = this.formTenantTransfer.getRawValue();
      let replacedData: any = {};

      Object.keys(formValue).forEach((key: string) => {
        formValue[key] == null ? replacedData[key] = '' : replacedData[key] = formValue[key]
      })
      Object.keys(formvalue2).forEach((key: string) => {
        formvalue2[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue2[key];
      })
      Object.keys(formvalue3).forEach((key: string) => {
        formvalue3[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue3[key];
      })
      Object.keys(formvalue4).forEach((key: string) => {
        formvalue4[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue4[key];
      })
      Object.keys(formvalue5).forEach((key: string) => {
        formvalue5[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue5[key];
      })

      const report = Object.assign({}, this.unit, replacedData);
      this.activeModal.close(report);
    } else {
      this.formTenantTransfer.controls['moveOutDate'].setValue(null);
      this.formTenantTransfer.controls['newUnitNumber'].setValue(null);
      this.formTenantTransfer.controls['dateOfTransfer'].setValue(null);
      this.showErrorMessage('There is a unit with the same unit number that is currently occupied. Please select a different unit number');
    }
  }

  public nonAdminVacant(){
    const formValue = this.formVacant.getRawValue();
    const formvalue2 = this.form3.getRawValue();
    const formvalue3 = this.formExemptTop.getRawValue();
    //const formvalue5 = this.formTenantTransfer.getRawValue();
    let replacedData: any = {};

    Object.keys(formValue).forEach((key: string) => {
      formValue[key] == null ? replacedData[key] = '' : replacedData[key] = formValue[key]
    })
    Object.keys(formvalue2).forEach((key: string) => {
      formvalue2[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue2[key];
    })
    Object.keys(formvalue3).forEach((key: string) => {
      formvalue3[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue3[key];
    })

    const report = Object.assign({}, this.unit, replacedData);
    this.activeModal.close(report);
  }

  public nonAdminMarket(){
    const formValue = this.formMarket.getRawValue();
    const formvalue2 = this.form3.getRawValue();
    const formvalue3 = this.formExemptTop.getRawValue();
    let replacedData: any = {};

    Object.keys(formvalue2).forEach((key: string) => {
      formvalue2[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue2[key];
    })
    Object.keys(formvalue3).forEach((key: string) => {
      formvalue3[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue3[key];
    })
    
    Object.keys(formValue).forEach((key: string) => {
      formValue[key] == null ? replacedData[key] = '' : replacedData[key] = formValue[key]
    })

    const unit = Object.assign({}, this.unit, replacedData);
    unit.unitLocked = true;
    this.activeModal.close(unit);
  }

  public nonAdminLastElseOrNonQualifiedOccupied(){
    const formValue = this.formNonQualifiedOccupied.getRawValue();
    const formvalue2 = this.form3.getRawValue();
    const formvalue3 = this.formExemptTop.getRawValue();
    let replacedData: any = {};

    Object.keys(formvalue2).forEach((key: string) => {
      formvalue2[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue2[key];
    })
    Object.keys(formvalue3).forEach((key: string) => {
      formvalue3[key] == null ? replacedData[key] = replacedData[key] : replacedData[key] = formvalue3[key];
    })
    
    Object.keys(formValue).forEach((key: string) => {
      formValue[key] == null ? replacedData[key] = '' : replacedData[key] = formValue[key]
    })

    const unit = Object.assign({}, this.unit, replacedData);
    this.activeModal.close(unit);
  }
  //#endregion
  
  close() {
    this.activeModal.dismiss();
  }

  lock(){
    this.form3.controls['unitLocked'].patchValue(true);
    if(this.form){
      this.form.controls['optionalFeeType'].disable();
      this.form.updateValueAndValidity();
      }

    if(this.formExemptTop.controls['exemptUnit'].value === true){
      this.formExempt.controls['optionalFeeType'].disable();
      this.formExempt.updateValueAndValidity();
    }
    if(this.formExemptTop.controls['marketUnit'].value === true){
      this.formMarket.controls['optionalFeeType'].disable();
      this.formMarket.updateValueAndValidity();
    }
    if(this.formExemptTop.controls['vacantUnit'].value === true){
      this.formVacant.updateValueAndValidity();
    }
    if(this.formExemptTop.controls['nonQualifiedOccupied'].value === true){
      this.formNonQualifiedOccupied.updateValueAndValidity();
    }
    if(this.formExemptTop.controls['tenantTransfer'].value === true){
      this.formTenantTransfer.controls['optionalFeeType'].disable();
      this.formTenantTransfer.updateValueAndValidity();
    }

  }

  unlock(){
    this.form3.controls['unitLocked'].patchValue(false);

    if(this.form.controls['optionalFee'].value > 0){
      this.form.controls['optionalFeeType'].enable();
      this.form.updateValueAndValidity();
    }
    if(this.formTenantTransfer.controls['optionalFee'].value > 0){
      this.formTenantTransfer.controls['optionalFeeType'].enable();
      this.formTenantTransfer.updateValueAndValidity();
    }
    if(this.formExempt.controls['optionalFee'].value > 0){
      this.formExempt.controls['optionalFeeType'].enable();
      this.formExempt.updateValueAndValidity();
    }
    if(this.formMarket.controls['optionalFee'].value > 0){
      this.formMarket.controls['optionalFeeType'].enable();
      this.formMarket.updateValueAndValidity();
    }
    if(this.formVacant.controls['optionalFee'].value > 0){
      this.formVacant.updateValueAndValidity();
    }
    if(this.formNonQualifiedOccupied.controls['optionalFee'].value > 0){
      this.formNonQualifiedOccupied.updateValueAndValidity();
    }
  }

  public exemptUnitChecked() {
    if(this.formExemptTop.value.exemptUnit === true){
      this.formType = 'Exempt';
      // moves the data to the new form if possible
      this.formExempt.patchValue(this.form.getRawValue());
    } else {
      this.form.patchValue(this.formExempt.getRawValue());
      this.formType = 'Normal';
    }
  }

  public marketUnitChecked() {
    if(this.formExemptTop.value.marketUnit === true){
      this.formType = 'Market'
      // moves the data to the new form if possible
      this.formMarket.patchValue(this.form.getRawValue());
    } else {
      this.form.patchValue(this.formMarket.getRawValue());
      this.formType = 'Normal'
    }
  }

  public tenantTransferChecked() {
    //BFS: 1.081 - 11.17.2020 added this check modal to make sure they are sure that they want to transfer this tenant
    const title = 'Tenant Transfer';
    const message = 'If transferring between buldings, is this household income eligible to be transferred to a different building? If so are you sure you want to make changes to this tenant? ';

    this.dialogsService.confirmation(title, message)
    .then(() => {
      if(this.formExemptTop.value.tenantTransfer === true){
        // Joe: chooses correct formType depending if previous qualified is checked or not
        if(this.formType ==  'Normal'){
          this.formType = 'Transfer';
        } else {
          this.formType = 'TransferPrevQual';
        }
       //normally moves the data over to the other form
       this.formTenantTransfer.patchValue(this.form.getRawValue());
    //BFS: 1.10.9 - 4.4.2022 people kept on not setting the correct building ID because it was auto populated. made it so that its empty and cannot save until they pick the correct building
    this.formTenantTransfer.controls['buildingID'].setValue('');
       if(this.formTenantTransfer.controls['optionalFee'].value > 0){
        this.formTenantTransfer.controls['optionalFeeType'].enable();
       }
      } else {
        if(this.formType == 'Transfer'){
          this.formType =  'Normal';
        } else {
          this.formType =  'NormalPrevQual';
        }
        this.form.patchValue(this.formTenantTransfer.getRawValue());
      }
    }, (error) => { console.log('%c error', 'background: yellow; color: black', error);})

  }

  public tenantTransferFormValidation(){
    if(this.formTenantTransfer.value.newUnitNumber !== '' && this.formTenantTransfer.value.newUnitNumber !== null &&
       this.formTenantTransfer.value.dateOfTransfer !== '' && this.formTenantTransfer.value.dateOfTransfer !== null && this.formTenantTransfer.value.buildingID !== null){
      this.formTenantTransfer.get('moveOutDate')!.setValue(this.formTenantTransfer.value.dateOfTransfer);
      this._updateTenantTransferCalculations();
      //this.form.setErrors(null);
    } else {
      this.formTenantTransfer.get('moveOutDate')!.setValue('');
      //this.form.setErrors( { 'invalid' : true } );
    }
  }

  public prevTaxCredQualifiedChecked() {
    if(this.formType == 'Normal' || this.formType == 'NormalPrevQual'){
      if(this.form.value.previouslyTaxCreditQualified === true){
        this.formType = 'NormalPrevQual';
        //BFS: 1.07 8.12.2020 - this forces the form to be invalid because once you click on the Prev. Qualified button, the form that appears needs to be 
        //completed AND THE CALCULATIONS ALL HAS TO APPROVE (say yes) for the form to be able to save
        this.form.setErrors( { 'invalid' : true } );
      } else {
        this.formType = 'Normal';
        // Joe: i put this here incase they accidently hit prev qualified checkbox and then undo it. dont want ot the form to be invalid
        this.form.setErrors( null );
      }
    } else {
      if(this.formTenantTransfer.value.previouslyTaxCreditQualified === true){
        this.formType = 'TransferPrevQual';
        //BFS: 1.07 8.12.2020 - this forces the form to be invalid because once you click on the Prev. Qualified button, the form that appears needs to be 
        //completed AND THE CALCULATIONS ALL HAS TO APPROVE (say yes) for the form to be able to save
        this.formTenantTransfer.setErrors( { 'invalid' : true } );
      } else {
        this.formType = 'Transfer';
        // Joe: i put this here incase they accidently hit prev qualified checkbox and then undo it. dont want ot the form to be invalid
        this.formTenantTransfer.setErrors( null );
      }
    }
  }

  public _prevTaxCredQualifiedCheckedCalculations() {
      if(this.formType == 'NormalPrevQual'){
        if(this.form.controls['previouslyTaxCreditQualified'].value === true){
          var tenantIncomeQualifiedPrevious = this.formPreviouslyTaxCreditQualified.controls['previousGrossIncome'].value < this.formPreviouslyTaxCreditQualified.controls['previousMoveInIncomeLimits'].value ? 'Yes' : 'No';
          //var tenantPaymentPrevious = this.formPreviouslyTaxCreditQualified.controls['previousUnitRent'].value -  this.formPreviouslyTaxCreditQualified.controls['previousRentSubsidy'].value;
          var tenantPaymentPrevious = this.formPreviouslyTaxCreditQualified.controls['previousUnitRent'].value;
          var tenantGrossRentPrevious = tenantPaymentPrevious +  this.formPreviouslyTaxCreditQualified.controls['previousUtilityAllowance'].value;
          var tenantRentQualifiedPrevious = tenantGrossRentPrevious <= this.formPreviouslyTaxCreditQualified.controls['previousMaximumRent'].value ? 'Yes' : 'No';
          var overallTenantEligiblePrevious = tenantIncomeQualifiedPrevious === 'Yes' && tenantRentQualifiedPrevious === 'Yes' ? 'Yes' : 'No';
          (document.getElementById('tenantIncomeQualifiedPrevious') as HTMLInputElement).value = tenantIncomeQualifiedPrevious;
          (document.getElementById('tenantGrossRentPrevious') as HTMLInputElement).value = tenantGrossRentPrevious;
          (document.getElementById('tenantRentQualifiedPrevious') as HTMLInputElement).value = tenantRentQualifiedPrevious;
          (document.getElementById('overallTenantEligiblePrevious') as HTMLInputElement).value = overallTenantEligiblePrevious;
  
  
          //BFS: 1.07 - TODO make it so that if the previous check is checked AND THEN
          //all the calculations are YES then SHOW THE SAVE BUTTON
          //IF THE CALCULATIONS DOESN'T SAY YES, DISABLE THE SAVE BUTTON AND the inputs can't be empty
          // JOE: 8.17.2020 reduced the if statement as all the calculations are already done above
          if(overallTenantEligiblePrevious === 'Yes'){
          //BFS: 1.07 8.13.2020 - if all the condistions are met, then let the save button be clickable
          this.form.setErrors(null);
          } else {
            //BFS: 1.07 - if not, then disable the save button (by setting the form invalid)
            this.form.setErrors( { 'invalid' : true } );
          }
        }
      } else {
        if(this.formTenantTransfer.controls['previouslyTaxCreditQualified'].value === true){
          var tenantIncomeQualifiedPrevious = this.formPreviouslyTaxCreditQualified.controls['previousGrossIncome'].value < this.formPreviouslyTaxCreditQualified.controls['previousMoveInIncomeLimits'].value ? 'Yes' : 'No';
          //var tenantPaymentPrevious = this.formPreviouslyTaxCreditQualified.controls['previousUnitRent'].value -  this.formPreviouslyTaxCreditQualified.controls['previousRentSubsidy'].value;
          var tenantPaymentPrevious = this.formPreviouslyTaxCreditQualified.controls['previousUnitRent'].value;
          var tenantGrossRentPrevious = tenantPaymentPrevious +  this.formPreviouslyTaxCreditQualified.controls['previousUtilityAllowance'].value;
          var tenantRentQualifiedPrevious = tenantGrossRentPrevious <= this.formPreviouslyTaxCreditQualified.controls['previousMaximumRent'].value ? 'Yes' : 'No';
          var overallTenantEligiblePrevious = tenantIncomeQualifiedPrevious === 'Yes' && tenantRentQualifiedPrevious === 'Yes' ? 'Yes' : 'No';
          (document.getElementById('tenantIncomeQualifiedPrevious') as HTMLInputElement).value = tenantIncomeQualifiedPrevious;
          (document.getElementById('tenantGrossRentPrevious') as HTMLInputElement).value = tenantGrossRentPrevious;
          (document.getElementById('tenantRentQualifiedPrevious') as HTMLInputElement).value = tenantRentQualifiedPrevious;
          (document.getElementById('overallTenantEligiblePrevious') as HTMLInputElement).value = overallTenantEligiblePrevious;
  
  
          //BFS: 1.07 - TODO make it so that if the previous check is checked AND THEN
          //all the calculations are YES then SHOW THE SAVE BUTTON
          //IF THE CALCULATIONS DOESN'T SAY YES, DISABLE THE SAVE BUTTON AND the inputs can't be empty
          // JOE: 8.17.2020 reduced the if statement as all the calculations are already done above
          if(overallTenantEligiblePrevious === 'Yes'){
          //BFS: 1.07 8.13.2020 - if all the condistions are met, then let the save button be clickable
          this.formTenantTransfer.setErrors(null);
          } else {
            //BFS: 1.07 - if not, then disable the save button (by setting the form invalid)
            this.formTenantTransfer.setErrors( { 'invalid' : true } );
          }
        }
      }
      
  }

  public getFileRatingTypes() {
    this.unitDetailsService.getFileRatingTypes()
    .subscribe((data) => {
      this.fileRatingTypes = data;
    });

  }

  public getSeniorTypes() {
    this.unitDetailsService.getSeniorTypes()
    .subscribe((data) => {
      this.seniorTypes = data;
    });

  }

  public getAccessibleTypes() {
    this.unitDetailsService.getAccessibleTypes()
    .subscribe((data) => {
      this.accessibleTypes = data;
    });

  }

  showErrorMessage(message: string) {
    return this.dialogsService.error(message)
  }

  public vacantUnitChecked(){
    if(this.formExemptTop.value.vacantUnit === true){
      this.formType = 'Vacant'
      // moves the data to the new form if possible
      this.formVacant.patchValue(this.form.getRawValue());
    } else {
      this.form.patchValue(this.formVacant.getRawValue());
      this.formType = 'Normal'
    }
  }

  public nonQualifiedOccupiedChecked(){
    if(this.formExemptTop.value.nonQualifiedOccupied === true){
      this.formType = 'NonQualifiedOccupied'
      // moves the data to the new form if possible
      this.formNonQualifiedOccupied.patchValue(this.form.getRawValue());
    } else {
      this.form.patchValue(this.formNonQualifiedOccupied.getRawValue());
      this.formType = 'Normal'
    }
  }
    
  private _buildingFilter(buildingID: number): Building[] {
    return this.buildings.filter(option => option.buildingID === buildingID);
  }

  public _optionalFee(){
    if(this.form.controls['optionalFee'].value > 0){
      this.form.controls['optionalFeeType'].enable();
    } else {
      this.form.controls['optionalFeeType'].disable();
    }
    if(this.form.controls['optionalFee'].value === 0){
      this.form.controls['optionalFeeType'].reset();
      this.form.controls['optionalFeeType'].disable();
    }

    if(this.formTenantTransfer.controls['optionalFee'].value > 0){
      this.formTenantTransfer.controls['optionalFeeType'].enable();
    } else {
      this.formTenantTransfer.controls['optionalFeeType'].disable();
    }
    if(this.formTenantTransfer.controls['optionalFee'].value === 0){
      this.formTenantTransfer.controls['optionalFeeType'].reset();
      this.formTenantTransfer.controls['optionalFeeType'].disable();
    }

    if(this.formExempt.controls['optionalFee'].value > 0){
      this.formExempt.controls['optionalFeeType'].enable();
    } else {
      this.formExempt.controls['optionalFeeType'].disable();
    }
    if(this.formExempt.controls['optionalFee'].value === 0){
      this.formExempt.controls['optionalFeeType'].reset();
      this.formExempt.controls['optionalFeeType'].disable();
    }

    if(this.formMarket.controls['optionalFee'].value > 0){
      this.formMarket.controls['optionalFeeType'].enable();
    } else {
      this.formMarket.controls['optionalFeeType'].disable();
    }
    if(this.formMarket.controls['optionalFee'].value === 0){
      this.formMarket.controls['optionalFeeType'].reset();
      this.formMarket.controls['optionalFeeType'].disable();
    }
  }
}
