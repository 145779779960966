import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, Report, Property } from '@app/core';
import { map } from 'rxjs/operators';
import {Helpers} from '../shared/helpers';
import { TrackingItem } from '@app/core/models/trackingItem';
import { PropertyTrackingItem } from '@app/core/models/propertyTrackingItem';
import { MaintenanceList } from '@app/core/models/maintenanceList';
import { ManagersByPropertyModel } from '@app/core/models/managersByPropertyModel';
@Injectable({ providedIn: 'root' })
export class PreventiveMaintenanceService {
  constructor(private http: HttpClient,
              private helper: Helpers) { }

  getAllPropertiesDropdownByEntity() {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/properties/getPropertiesDropdownByEntity/1/100000`);
  }

  getMaintenanceStatusTypes() {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/preventiveMaintenance/getMaintenanceStatusTypes`);
  }

  getFrequencyTypes() {
    return this.http.get<any>(`${AppConfig.apiEndpoint}/preventiveMaintenance/getFrequencyTypes`);
  }

  getTrackingItems() {
    return this.http.get<{ list: TrackingItem[] }>(`${AppConfig.apiEndpoint}/preventiveMaintenance/getTrackingItems/1/10000`)
      .pipe(
      map(response => {
        return response;
        })
      );
  }

  getPropertyTrackingItems(propertyId: number) {
    return this.http.get<PropertyTrackingItem[]>(`${AppConfig.apiEndpoint}/preventiveMaintenance/getPropertyTrackingitems/${propertyId}`);
  }

  getManagersByProperty(propertyId: number) {
    return this.http.get<ManagersByPropertyModel[]>(`${AppConfig.apiEndpoint}/preventiveMaintenance/getManagersByProperty/${propertyId}`);
  }

  updatePropertyTrackingItem(item: PropertyTrackingItem) {
    return this.http.put(`${AppConfig.apiEndpoint}/preventiveMaintenance/updatePropertyTrackingItem`, item);
  }

  addPropertyTrackingItem(item: PropertyTrackingItem) {
    return this.http.post(`${AppConfig.apiEndpoint}/preventiveMaintenance/addPropertyTrackingItem`, item);
  }

  addAllPropertyTrackingItem(item: any[]) {
    return this.http.post(`${AppConfig.apiEndpoint}/preventiveMaintenance/addAllPropertyTrackingItem`, item);
  }

  deletePropertyTrackingItem(propertyTrackingItemID: number) {
    return this.http.delete(`${AppConfig.apiEndpoint}/preventiveMaintenance/deletePropertyTrackingItem/${propertyTrackingItemID}`);
  }

  getMaintenanceListByProperty(propertyId: number) {
    return this.http.get<MaintenanceList[]>(`${AppConfig.apiEndpoint}/preventiveMaintenance/getMaintenanceListByProperty/${propertyId}`);
  }

  getMaintenanceListByPropertyTrackingItem(propertyTrackingItemID: number) {
    return this.http.get<MaintenanceList[]>(`${AppConfig.apiEndpoint}/preventiveMaintenance/getMaintenanceListByPropertyTrackingItem/${propertyTrackingItemID}`);
  }

  addMaintenanceList(item: MaintenanceList) {
    console.log('%c item', 'background: yellow; color: black', item);
    return this.http.post(`${AppConfig.apiEndpoint}/preventiveMaintenance/addMaintenanceList`, item);
  }

  updateMaintenanceList(item: MaintenanceList) {
    return this.http.put(`${AppConfig.apiEndpoint}/preventiveMaintenance/updateMaintenanceList`, item);
  }

  deleteMaintenanceList(maintenanceListID: number) {
    return this.http.delete(`${AppConfig.apiEndpoint}/preventiveMaintenance/deleteMaintenanceList/${maintenanceListID}`);
  }

}
