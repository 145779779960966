export const environment = {
    production: true,
    apiUrl: "https://epiqpm-test-api.azurewebsites.net/api",
    auth: {
      clientId: '46291ff0-b51b-4d22-902d-cfd2b0d3e154',
      authority: "https://login.microsoftonline.com/38c03ff2-6b35-422e-aae9-494e72cdf508/",
      validateAuthority: true,
      redirectUri: "https://epiqpm-test.azurewebsites.net/ssoauth",
      postLogoutRedirectUri: "https://epiqpm-test.azurewebsites.net/",
      navigateToLoginRequestUrl: false
    }
  };
  