import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from "rxjs";

import { AuthService } from "../../services/auth.service";
import { UserService } from '../../../users/user.service';
import { User } from '@app/core';
import { sidebarMenuItems } from "./sidebar.config";
import { ProfileDialogComponent } from '@app/shared/dialogs';
import { DialogsService } from "@app/core/services/dialogs.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit, OnDestroy {
  isLoggedIn = false;
  subscription: Subscription | null = null
  displayName = "";
  sidebarMenuItems = sidebarMenuItems;
  currentUser: User | undefined;
  public userEntity: string[] | null | undefined;

  constructor(private authService: AuthService,    
    private modalService: NgbModal,
    private dialogsService: DialogsService,
    private userService: UserService) { }

  ngOnInit() {
    this.userEntity = this.authService.authUserEntity();
    var isAdmin = this.authService.isAuthUserInRole("Admin");

    if(this.userEntity){
      if(isAdmin === false){
        if(this.userEntity.indexOf('cpp') > -1 && !(this.userEntity.indexOf('wnc') > -1)){
          for (let i = 0; i < sidebarMenuItems.length; i++) {
            if(sidebarMenuItems[i].title === 'Unit Details'){
              sidebarMenuItems.splice(i, 1);
            }
          }
        }
      }
    }

    this.subscription = this.authService.authStatus$.subscribe(status => {
      this.isLoggedIn = status;
      if (status) {
        const authUser = this.authService.getAuthUser();
        this.displayName = authUser ? authUser.displayName : "";
      }
    });

    this.userService.getMe()
    .subscribe((data) => {
      this.currentUser = data;
        if(this.currentUser.needToChangePassword){
          this.openProfile();
        }
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public openProfile() {
    this.openProfileDialog(this.currentUser as User)
      .then((user) => {
        return this.userService.updateMe(user).subscribe(() => {
        this.userService.getMe().subscribe((data) => {
          this.currentUser = data;
        })
      });
      }, () => {});
  }

  logout() {
    this.authService.logout(true);
  }

  private openProfileDialog(user: User) {
    const modalRef = this.modalService.open(ProfileDialogComponent,{
      beforeDismiss: ():any => {
        if (modalRef.componentInstance.form.dirty) {
          return this.showConfirmationMessage()
        }
        return true;
      }
    });
    modalRef.componentInstance.user = user;

    return modalRef.result;
  }

  showConfirmationMessage() {
    const title = 'Confirmation';
    const message = 'This form will not be saved, are you sure you want to exit this form?';
    return this.dialogsService.confirmation(title, message)
  }
}
