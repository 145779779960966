import { Injectable } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { HttpClient } from '@angular/common/http';
import { AppConfig, TokenStoreService, AuthTokenType } from '@app/core';
import { Files } from '@app/core/models/files';
import { param } from 'jquery';

@Injectable({ providedIn: 'root' })
export class FileUploaderService {
  private authorizationHeader = "Authorization";
  
  constructor(
    private http: HttpClient,
    private tokenStoreService: TokenStoreService,
  ) { }

  public params: any;

  
  public options: any= {
    url: AppConfig.apiEndpoint + '/file/upload',
    isHTML5: true,
    autoUpload: false,
    
    headers: [{name: this.authorizationHeader, value: `Bearer `}],
          
    removeAfterUpload: false,
    itemAlias: 'file',
    additionalParameter: undefined
  };

  public fileUploader: FileUploader = new FileUploader(this.options);

  public setOptions(params: any, entity: string){
    if(params && entity === 'pef'){
      params.entityId = 3;
      this.fileUploader.options.additionalParameter = params;
    }
    if(params && entity === 'cpp'){
      params.entityId = 2;
      this.fileUploader.options.additionalParameter = params;
    }
    if(params && entity === 'wnc'){
      params.entityId = 1;
      this.fileUploader.options.additionalParameter = params;
    }

    var accessToken = this.tokenStoreService.getRawAuthToken(AuthTokenType.AccessToken);
    this.fileUploader.options.headers =  [{name: this.authorizationHeader, value: `Bearer ${accessToken}`}, 
                                          {name:'Cache-Control',value:'no-cache, no-store, must-revalidate, post-check=0, pre-check=0'},
                                          {name:'Pragma',value:'no-cache'},
                                          {name:'Expires',value:'0'}];
    this.fileUploader.uploadAll();
  }

  public add(_params: { [key: string]: any }) {
    this.fileUploader.onAfterAddingFile = (item => {
      item.withCredentials = false;
    });
    return this.fileUploader
  }

  delete(id: number) {
    return this.http.delete(`${AppConfig.apiEndpoint}/file/delete?id=${id}`);
  }

  download(id: number) {
    return this.http.get(`${AppConfig.apiEndpoint}/file/download?id=${id}`, {responseType:'blob', observe: 'response'});
  }

  downloadFile(filePath: string) {
    return this.http.get(`${AppConfig.apiEndpoint}/file/getFile/${filePath}`, {responseType:'blob', observe: 'response'});
  }

  getAll() {
    return this.http.get<Files[]>(`${AppConfig.apiEndpoint}/file/all`);
  }
}
