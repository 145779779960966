import { AbstractControl, Validator, ValidatorFn, Validators, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export const hasSpecialChartersValidator = (control: AbstractControl): { [key: string]: boolean } => {
    const value = control.value;
    const pattern = /[!#$&^~<>?{}[\]àáâÀÁÂ'\”\\]+/i;
    return pattern.test(value) ? {'hasSpecialCharters': true} : {'hasSpecialCharters': false};
};

export const existValidator = (array: [any]): ValidatorFn  => {
    return (control: AbstractControl): {[key: string]: boolean} | null => {
        const username = control.value;
        if(array.includes(username)) {
            return {'userExist': true}
        } else {
            return null
        }
    };
};

export const emailValidator = (control: AbstractControl): { [key: string]:  boolean } | null => {
    const value = control.value;
    const pattern = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,}(?:\.[a-z]{2})?)$/i;
    if(pattern.test(value) === false) {
        return  {'email': true}
    } else {
        return null
    }
};

export const passwordValidator = (control: AbstractControl): { [key: string]:  boolean } | null => {
    const value = control.value;
    const upperRegex   = /[A-Z]/;
    const numberRegex  = /[0-9]/;
    const specialRegex = /[^A-Za-z0-9]/;
  
    if(value.length < 6) { return {'passLength': true} }
    if(!value.match(upperRegex))  { return {'passNotUpper': true} }
    if(!value.match(numberRegex)) { return {'passNotNumber': true} }
    if(!value.match(specialRegex)){ return {'passNotSpecial': true} }

    return null;
};

export const dateValidator = (): ValidatorFn => {
    return (group: any) => {
        
        const startDate = group.controls['startDate'];
        const endDate = group.controls['endDate'];
        if(startDate.value > endDate.value) {
            endDate.setErrors({'earlierDate': true })
        } else {
            endDate.setErrors(null)
        }       
        return null;
    };
}

export const dateValidator2 = (control: AbstractControl): { [key: string]: boolean } | null => {

  const value = control.value;
  const dateRegex = /\d{4}\-\d{2}\-\d{2}/;
  if(!value){
      return null;
  }
  if (typeof value === "string") {
    if (!value.match(dateRegex)) { return { 'dateInvalid': true } }
  } 
  
  if (!(value instanceof Date)) { return { 'dateInvalid': true } }
  else if(value.getFullYear() > 9999) {
    return { 'dateInvalid': true } 
  }

  return null;
};
export const dateValidatorAllowBlank = (control: AbstractControl): { [key: string]: boolean } | null => {

    const value = control.value;
    const dateRegex = /\d{4}\-\d{2}\-\d{2}/;
    if(!value){
        return null;
    }
    if (typeof value === "string") {
        if(value !== ''){
            if (!value.match(dateRegex)) { return { 'dateInvalid': true } }
        } else{
            return null;
        }
    } 
    if (!(value instanceof Date)) { return { 'dateInvalid': true } }
    else if(value.getFullYear() > 9999) {
      return { 'dateInvalid': true } 
    }
  
    return null;
  };

export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

export function TotalApplicantValueCheck(totalApplicantControlName: string, vacantUnitControlName: string, totalUnitControlName:string) {
    return (formGroup: UntypedFormGroup) => {
        const totalApplicantControl = formGroup.controls[totalApplicantControlName];
        const vacantUnitControl = formGroup.controls[vacantUnitControlName];
        const totalUnitControl = formGroup.controls[totalUnitControlName];

        if (totalApplicantControl.errors && !totalApplicantControl.errors.totalApplicantValueCheck) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (parseInt(totalApplicantControl.value) > (parseInt(vacantUnitControl.value) + parseInt(totalUnitControl.value))) {
            totalApplicantControl.setErrors({ totalApplicantValueCheck: true });
        } else {
            totalApplicantControl.setErrors(null);
        }
    }
}
