<ul class="nav flex-column">
  <li class="nav-item" *ngFor="let item of sidebarMenuItems"
      [ngbPopover]="item.title"
      triggers="mouseenter:mouseleave"
      isVisibleForAuthUser [isVisibleForRoles]="item.isVisibleForRoles"
      [isVisibleForEntities]="item.isVisibleForEntities"
      placement="right">
    <a class="nav-link" [routerLink]="[item.url]" routerLinkActive="active">
      <i [ngClass]="'nav-icon fa fa-' + item.icon"></i>
      <span class="nav-title">{{ item.title }}</span>
    </a>
  </li>

  <li class="nav-separator"></li>

  <li class="nav-item" [ngbPopover]="'User Profile'"
      triggers="mouseenter:mouseleave"
      placement="right">
    <a class="nav-link" (click)="openProfile()" title="User Profile">
      <i class="nav-icon fa fa-user-circle"></i>
      <span class="nav-title">User Profile</span>
    </a>
  </li>
  <li class="nav-item" [ngbPopover]="'Logout'"
      triggers="mouseenter:mouseleave"
      placement="right">
    <a class="nav-link" (click)="logout()" title="Logout">
      <i class="nav-icon fa fa-sign-out"></i>
      <span class="nav-title">Logout</span>
    </a>
  </li>
</ul>
