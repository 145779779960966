import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from '@app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from '@app/shared/dialogs/error-dialog/error-dialog.component';
import { AlertDialogComponent } from '@app/shared/dialogs/alert-dialog/alert-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogsService {

  constructor(
    private modalService: NgbModal
  ) { }

  public confirmation(title: string, message: string, okText?: string, cancelText?: string) {
    const modalRef = this.modalService.open(ConfirmationDialogComponent);
    modalRef.componentInstance.title = title;    
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.okText = okText;
    modalRef.componentInstance.cancelText = cancelText;
    return modalRef.result;
  }

  public error(message: string) {
    const modalRef = this.modalService.open(ErrorDialogComponent);
    modalRef.componentInstance.message = message;
    return modalRef.result;
  }

  public alert(title: string, message: string, ) {
    const modalRef = this.modalService.open(AlertDialogComponent);
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.title = title;
    return modalRef.result;
  }
}
