<div class="modal-header">
  <h4 class="modal-title">{{ dialogTitle }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <!-- child component valueChange is from the child component file-uploader.component.ts and the successFileUpload is a function thats in the parent component that gets called whenever the value is changed-->
  <file-uploader [params]="{}" (valueChange)='successFileUpload($event)'>
    <div class="file-uploader-box" *ngIf = "!fileList.length">
        <h5>No uploaded files</h5>
    </div>
    <div class="file-uploader-box" *ngIf = "fileList.length">
      <div class="file-uploader-file" *ngFor="let item of fileList">
        <div class="name-file">
          <span>{{ item.name }}</span>
        </div>
        <div class="file-uploader-buttons">
          <button class="btn btn-light btn-sm" title="Download" (click)="getDownloadURL(item.id, item.name)">
            <i class="fa fa-download"  aria-hidden="true" *ngIf= "!isDownloading[item.id]"></i>
            <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf= "isDownloading[item.id]"></i>
          </button>
          <button class="btn btn-danger btn-sm" title="Delete" (click)="deleteFile(item.id)" [disabled]="!canDelete">
            <i class="fa fa-trash" aria-hidden="true" *ngIf= "!isDeleting[item.id]"></i>
            <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf= "isDeleting[item.id]"></i>
          </button>
        </div>
      </div>
    </div>
  </file-uploader>
</div>

<div class="modal-footer">  
  <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
  <button type="button" class="btn btn-primary" (click)="save()">Save</button>
</div>