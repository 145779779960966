import { Injectable, Directive, Input } from '@angular/core';
import {NgControl} from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class Helpers {
    constructor() { }


    formatMoney(amount: any) {
        try {
            amount = amount.value;
            const decimalCount = 2;
            const decimal = ".";
            const thousands = ","
            
            const negativeSign = Number(amount) < 0 ? "-" : "";
      
            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;
            return negativeSign + '$' + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(Number(amount) - Number(i)).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
          console.log(e)
          return ""
        }
      };
}