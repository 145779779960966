<div class="wncModal">
    <div class="panelHeader">
      <div class="row">
        <div class="col-md-9 panelTitle">
          <div class="col-md-12 no-pad">
            <h3 class="white-text header-padding-left">Tracking Items</h3>
          </div>
        </div>      
        <div class="col-md-3 panelButton">
          <button type="button"  class="btn btn-outline-primary white-background"  (click)="showNewTrackingItemDiv()"><i class="fa fa-plus" aria-hidden="true"></i> &#160;Add</button>
          <button type="button" class="btn btn-outline-danger white-background" (click)="closeModal()"><i class="fa fa-times" aria-hidden="true"></i> &#160;Close</button>
        </div>
      </div>
    </div>  
    <div class="panelBody">
      <div class="col-md-12 table-header">
        <div class="row">
          <div class="col-md-2 align-center bold-header header-text-size">
            <span>Tracking Item</span>
          </div>
          <div class="col-md-1 align-center bold-header header-text-size">
            <span class="required">Frequency</span>
            <button style="padding-left: 5px;" type="button" class="btn no-pad dropDownButton" placement="right" (click)="editFrequencyType()"
            [ngbPopover]="editFrequencyTypePopover" triggers="mouseenter:mouseleave" placement="right"><i class="fa fa-plus" aria-hidden="true"></i></button>
          </div>
          <div class="col-md-1 align-center bold-header header-text-size">
            <span>Send Attachment?</span>
          </div>
          <div class="col-md-5 align-center bold-header header-text-size">
            <span>File</span>
          </div>
          <div class="col-md-1 align-center bold-header header-text-size">
            <span>Backup Doc?</span>
          </div>
          <div class="col-md-1 align-center bold-header header-text-size">
            <span>Active</span>
          </div>
          <div class="col-md-1 align-center bold-header header-text-size">
            <span>Action</span>
          </div>
        </div>
      </div>

      <div class="col-md-12 table-content">
        <div class="row formInput" *ngIf="newTrackingItemShowDiv" style="padding-bottom: 4px;">
          <div class="col-md-2"  style="padding-right: 3px;">
            <input type="text" class="form-control align-right" [(ngModel)]="newTrackingItem.trackingItemName" />
          </div>
          <div class="col-md-1 no-pad slight-right-input-padding required">
            <select class="form-control align-center" [(ngModel)]="newTrackingItem.frequencyTypeID">
                <option value="{{0}}"></option>
                <option *ngFor="let ft of frequencyTypeList" [value]="ft.frequencyTypeID">{{ft.frequencyTypeName}}</option>
            </select>
          </div>
          <div class="col-md-1 no-pad">
            <input class="form-control checkbox-padding checkbox-margin" id="bold" type="checkbox" [(ngModel)]="newTrackingItem.sendWithAttachment"/>
          </div>
          <div class="col-md-5 no-pad">
            <file-uploader *ngIf="newTrackingItem.sendWithAttachment === true" (valueChange)='successFileUpload($event)' [params]="params"  class="" [entity]="'cpp'"
            (closeModal)="closeModal()" [url]="newTrackingItem.filePath" [fileName]="newTrackingItem.fileName"> 
              <div class="file-uploader-box" *ngIf ="fileList.length">
                <div class="file-uploader-file" *ngFor="let item of fileList">
                  <div class="name-file">
                    <span>{{ item.name }}</span>
                  </div>
                  <div class="file-uploader-buttons">
                    <button class="btn btn-light btn-sm" title="Download" (click)="filesDialogComponent.getDownloadURL(item.id, item.name)">
                      <i class="fa fa-download"  aria-hidden="true" *ngIf= "!filesDialogComponent.isDownloading[item.id]"></i>
                      <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf= "filesDialogComponent.isDownloading[item.id]"></i>
                    </button>
                    <button class="btn btn-danger btn-sm" title="Delete" (click)="filesDialogComponent.deleteFile(item.id)" [disabled]="!filesDialogComponent.canDelete">
                      <i class="fa fa-trash" aria-hidden="true" *ngIf= "!filesDialogComponent.isDeleting[item.id]"></i>
                      <i class="fa fa-spinner fa-spin" aria-hidden="true" *ngIf= "filesDialogComponent.isDeleting[item.id]"></i>
                    </button>
                  </div>
                </div>
              </div>
            </file-uploader>  
          </div>
          <div class="col-md-1 no-pad">
            <input class="form-control checkbox-padding checkbox-margin" id="bold" type="checkbox" [(ngModel)]="newTrackingItem.backUpDocRequired"/>
          </div>
          <div class="col-md-1 no-pad">
            <input class="form-control checkbox-padding checkbox-margin" id="bold" type="checkbox" [(ngModel)]="newTrackingItem.active"/>
          </div>

          <div class="col-md-1 row left-pad top-pad">
            <div class="col-xs-6 col-md-6 no-pad atc-save-btn text-right" style="padding-left: 35px;">
              <img class="cursor-img" src="../../../../assets/image/ic_save.png" alt="" (click)="saveTrackingItem(newTrackingItem)" />
            </div>
    
            <div class="col-xs-6 col-md-6 no-pad atc-save-btn text-right" style="padding-right: 20px;">
              <img class="cursor-img" src="../../../../assets/image/ic_delete.png" alt="" (click)="closeNewTrackingItemDiv()" />
            </div>
          </div>
        </div>



        <div class="row formInput" *ngFor="let item of trackingItems; let ndx = index" style="padding-bottom: 4px;">
          <div class="col-md-2" style="padding-right: 3px;">
            <input type="text" class="form-control align-right " [(ngModel)]="item.trackingItemName" />
          </div>
          <div class="col-md-1 no-pad slight-right-input-padding required">
            <select [disabled]="item.disableSaveButton" class="form-control align-center" [(ngModel)]="item.frequencyTypeID">
                <option value="{{0}}"></option>
                <option *ngFor="let ft of frequencyTypeList" [value]="ft.frequencyTypeID">{{ft.frequencyTypeName}}</option>
            </select>
          </div>
          <div class="col-md-1 no-pad">
            <input class="form-control checkbox-padding checkbox-margin" id="bold" type="checkbox" [(ngModel)]="item.sendWithAttachment"/>
          </div>
          <div class="col-md-5 no-pad">
            <file-uploader *ngIf="item.sendWithAttachment === true" (valueChange)='successFileUpload($event)' [params]="params"  class="" [entity]="'cpp'"
            (closeModal)="closeModal()" [url]="item.filePath" [fileName]="item.fileName"> 
            </file-uploader>
      
          </div>
          <div class="col-md-1 no-pad">
            <input class="form-control checkbox-padding checkbox-margin" id="bold" type="checkbox" [(ngModel)]="item.backUpDocRequired"/>
          </div>
          <div class="col-md-1 no-pad">
            <input class="form-control checkbox-padding checkbox-margin" id="bold" type="checkbox" [(ngModel)]="item.active"/>
          </div>
          <div class="col-md-1 row left-pad top-pad">
            <div class="col-xs-6 col-md-6 no-pad atc-save-btn text-right" style="padding-left: 35px;">
              <img class="cursor-img" src="../../../../assets/image/ic_save.png" alt="" (click)="saveTrackingItem(item)" />
            </div>
            <div class="col-xs-6 col-md-6 no-pad atc-save-btn text-right" style="padding-right: 20px;">
              <img class="cursor-img" src="../../../../assets/image/ic_delete.png" alt="" (click)="deleteTrckingItem(item, ndx)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>