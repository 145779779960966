<div class="modal-header">
  <h4 class="modal-title">{{ dialogTitle }}</h4>
  <button type="button" class="close close-margin" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form [formGroup]="form">
    <!--row 1 property and user name-->
    <div class="form-row">
      <div class="form-group col-md-8 required">
        <label class="control-label">Property: </label><i class="question-space fa fa-question-circle hover-pointer"
          [ngbPopover]="propertyNamePopover" triggers="mouseenter:mouseleave" placement="bottom"></i>
        <!--<button mat-raised-button
                matTooltip="Info about the action"
                aria-label="Button that displays a tooltip when focused or hovered over">
          Action
        </button>-->
        <select class="form-control" formControlName="propertyId"
          [class.error]="(isFailedForm ||form.get('propertyId')!.touched) && form.get('propertyId')!.invalid">
          <option value="" disabled>-</option>
          <option *ngFor="let property of properties" [ngValue]="property.id">{{property.name}}</option>
        </select>
        <span class="error-text"
          *ngIf="(isFailedForm ||form.get('propertyId')!.touched) && form.get('propertyId')!.hasError('required')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-4">
        <label class="control-label">User Name:</label>
        <input class="form-control" formControlName="userName" [readonly]="true" />
      </div>
    </div>
    <hr>
    <!--row 2 startdate and enddate-->
    <div class="form-row">
      <div class="form-group col-md-3 required">
        <label class="control-label">Start Date:</label><i class="question-space fa fa-question-circle hover-pointer"
          [ngbPopover]="startDatePopover" triggers="mouseenter:mouseleave" placement="top"></i>
        <div class="input-group">
          <input class="form-control" formControlName="startDate" name="dp"
            [class.error]="(isFailedForm ||form.get('startDate')!.touched) && form.get('startDate')!.invalid"
            [minDate]="{year: 1960, month: 1, day: 1}" [maxDate]="{year: 2050, month: 1, day: 1}" ngbDatepicker
            #d1="ngbDatepicker" [markDisabled]="isDisabled" (ngModelChange)="onDateChange($event)" [readonly]="true" >
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
          </div>
        </div>
        <span class="error-text"
          *ngIf="(isFailedForm ||form.get('startDate')!.touched) && form.get('startDate')!.hasError('required')">
          This field is required
        </span>
      </div>
      <div class="form-group col-md-3 required">
        <label class="control-label">End Date:</label>
        <div class="input-group">
          <input class="form-control" formControlName="endDate" name="dp"
            [class.error]="(isFailedForm ||form.get('endDate')!.touched) && form.get('endDate')!.invalid"
            [minDate]="{year: 1960, month: 1, day: 1}" [maxDate]="{year: 2050, month: 1, day: 1}" ngbDatepicker
            #d2="ngbDatepicker" [readonly]="true">
          <!--<div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
          </div>-->
        </div>
        <span class="error-text"
          *ngIf="(isFailedForm ||form.get('endDate')!.touched) && form.get('endDate')!.hasError('required')">
          This field is required
        </span>
        <span class="error-text"
          *ngIf="(isFailedForm ||form.get('endDate')!.touched) && form.get('endDate')!.hasError('earlierDate')">
          End date cannot be earlier than Start Date
        </span>
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Phone Traffic:</label><i class="question-space fa fa-question-circle hover-pointer"
          [ngbPopover]="phoneTrafficPopover" triggers="mouseenter:mouseleave" placement="top"></i>
        <input class="form-control" formControlName="phoneTraffic" type="number" />
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Walk-in Traffic:</label><i
          class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="walkInTrafficPopover"
          triggers="mouseenter:mouseleave" placement="top"></i>
        <input class="form-control" formControlName="walkInTraffic" type="number" />
      </div>

    </div>
    <hr>
    <!--row 3 the Units-->
    <div class="form-row">
      <div class="form-group col-md-3">
        <label class="control-label">Occupied Unit:</label><i class="question-space fa fa-question-circle hover-pointer"
          [ngbPopover]="occupiedUnitsPopover" triggers="mouseenter:mouseleave" placement="top"></i>
        <input class="form-control" formControlName="occupiedUnit" type="number" />
      </div>
      <div class="form-group col-md-3 required">
        <label class="control-label">Vacant Unit:</label><i class="question-space fa fa-question-circle hover-pointer"
          [ngbPopover]="vacantUnitsPopover" triggers="mouseenter:mouseleave" placement="top"></i>
        <input class="form-control" formControlName="vacantUnit" type="number"  
        [class.error]="(isFailedForm ||form.get('vacantUnit')!.touched) && form.get('vacantUnit')!.invalid" />
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Non-Revenue Unit:</label><i
          class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="nonRevenueUnitsPopover"
          triggers="mouseenter:mouseleave" placement="top"></i>
        <input class="form-control" formControlName="nonRevenueUnit" type="number" />
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Employee Unit:</label><i class="question-space fa fa-question-circle hover-pointer"
          [ngbPopover]="employeeUnitsPopover" triggers="mouseenter:mouseleave" placement="top"></i>
        <input class="form-control" formControlName="employeeUnit" type="number" />
      </div>
    </div>
    <hr>
    <!--row 4 vacant-->
    <div class="form-row">
      <div class="form-group col-md-3">
        <label class="control-label">Vacant Ready:</label><i class="question-space fa fa-question-circle hover-pointer"
          [ngbPopover]="vacantReadyPopover" triggers="mouseenter:mouseleave" placement="top"></i>
        <input class="form-control" formControlName="vacantReady" type="number" />
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Max Days Vacant:</label><i
          class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="maxDaysVacantPopover"
          triggers="mouseenter:mouseleave" placement="top"></i>
        <input class="form-control" formControlName="maxDaysVacant" type="number" type="number" />
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Move In's:</label><i class="question-space fa fa-question-circle hover-pointer"
          [ngbPopover]="moveInsPopover" triggers="mouseenter:mouseleave" placement="top"></i>
        <input class="form-control" formControlName="moveIns" type="number" />
        <!--<div class="input-group">
              <input class="form-control" formControlName="moveInDate" name="dp" ngbDatepicker
                 #d3="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d3.toggle()" type="button"></button>
          </div>
        </div>-->
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Move Out's:</label><i class="question-space fa fa-question-circle hover-pointer"
          [ngbPopover]="moveOutsPopover" triggers="mouseenter:mouseleave" placement="top"></i>
        <input class="form-control" formControlName="moveOuts" type="number" />
        <!--<div class="input-group">
              <input class="form-control" formControlName="moveOutDate" name="dp" ngbDatepicker
               #d4="ngbDatepicker">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="d4.toggle()" type="button"></button>
        </div>
            </div>-->
      </div>
    </div>
    <hr>
    <!--row 5 the total applicants, approved, and notice-->
    <div class="form-row">
      <div class="form-group col-md-3 required">
        <label class="control-label">Total Notice:</label><i class="question-space fa fa-question-circle hover-pointer"
          [ngbPopover]="totalNoticePopover" triggers="mouseenter:mouseleave" placement="top"></i>
        <input class="form-control" formControlName="totalNotice" type="number" 
        [class.error]="(isFailedForm ||form.get('totalNotice')!.touched) && form.get('totalNotice')!.invalid"/>
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Total Applicant:</label><i
          class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="totalApplicantsPopover"
          triggers="mouseenter:mouseleave" placement="top"></i>
        <input class="form-control" formControlName="totalApplicant" type="number" />
        <span class="error-text"
        *ngIf="(isFailedForm ||form.get('totalApplicant')!.touched) && form.get('totalApplicant')!.hasError('totalApplicantValueCheck')">
        Total Applicants value cannot exceed 'vacant + total notice' amount</span>
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Total Approved:</label><i
          class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="totalApprovedPopover"
          triggers="mouseenter:mouseleave" placement="top"></i>
        <input class="form-control" formControlName="totalApproved" type="number" />
      </div>
      
    </div>
    <hr>
    <!--row 6 applications-->
    <div class="form-row">
      <div class="form-group col-md-3">
        <label class="control-label">New Application:</label><i
          class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="newApplicationPopover"
          triggers="mouseenter:mouseleave" placement="top"></i>
        <input class="form-control" formControlName="newApplication" type="number" />
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Cancelled Application:</label><i
          class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="cancelledApplicationPopover"
          triggers="mouseenter:mouseleave" placement="top"></i>
        <input class="form-control" formControlName="cancelledApplication" type="number" />
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">New Notice:</label><i class="question-space fa fa-question-circle hover-pointer"
          [ngbPopover]="newNoticePopover" triggers="mouseenter:mouseleave" placement="top"></i>
        <input class="form-control" formControlName="newNotice" type="number" />
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Cancelled Notice:</label><i
          class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="cancelledNoticePopover"
          triggers="mouseenter:mouseleave" placement="top"></i>
        <input class="form-control" formControlName="cancelledNotice" type="number" />
      </div>
    </div>
    <hr>
    <!--row 7 the delinquents-->
    <div class="form-row">
      <div class="form-group col-md-3">
        <label class="control-label">Delinquent - Current:</label><i
          class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="delinquentCurrentPopover"
          triggers="mouseenter:mouseleave" placement="top"></i>
        <input class="form-control" formControlName="currentDelinquent" type="number" />
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Delinquent - Period:</label><i
          class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="delinquentPeriodPopover"
          triggers="mouseenter:mouseleave" placement="top"></i>
        <input class="form-control" formControlName="priorDelinquent" type="number" />
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Delinquent - Beyond:</label><i
          class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="delinquentBeyondPopover"
          triggers="mouseenter:mouseleave" placement="top"></i>
        <input class="form-control" formControlName="beyondDelinquent" type="number" />
      </div>
    </div>
    <hr>
    <!--row 8-->
    <div class="form-row">
      <div class="form-group col-md-12">
        <label class="control-label">Report Comment:</label><i
          class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="reportCommentsPopover"
          triggers="mouseenter:mouseleave" placement="top"></i>
        <textarea class="form-control" formControlName="reportComment"></textarea>
      </div>
    </div>
    
    <hr>
    <div class="form-row">
      <div class="form-group col-md-3">
        <label class="control-label"># of Units w/ Bed Bugs:</label>
        <input class="form-control" formControlName="unitsWithBedBugs" [attr.disabled]="unitsWithPestsSectionVisible() ? null : ''" type="number" />
      </div>
      <div class="form-group col-md-3">
        <label class="control-label">Pest Control Engaged:</label>
        <input class="form-control" formControlName="pestControlEngaged" [attr.disabled]="unitsWithPestsCheckBoxEnabled() ? null : ''" type="checkbox" />
      </div>
      <div class="form-group col-md-6">
        <label class="control-label">Units w/ Bed Bugs Comment::</label>
        <textarea class="form-control" formControlName="unitsWithBedBugsComment" [attr.disabled]="unitsWithPestsCheckBoxEnabled() ? null : ''"></textarea>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div *ngIf="!isAdd" class="w-100">
    <div class="row">
      <div class="col-3">Created:</div>
      <div class="col">
        <small>
          {{form.get("createdByName")!.value}},
          {{ form.get("createdDate")!.value | date : "short" }}
        </small>
      </div>
    </div>
    <div class="row">
      <div class="col-3">Modified:</div>
      <div class="col">
        <small>
          {{form.get("updatedByName")!.value}},
          {{ form.get("updatedDate")!.value | date : "short" }}
        </small>
      </div>
    </div>
  </div>
  <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
  <button type="button" class="btn btn-primary" (click)="save()">Save</button>
</div>