import { Directive, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "@app/core";
import { Subscription } from "rxjs";

@Directive({
  selector: "[isVisibleForAuthUser]"
})
export class IsVisibleForAuthUserDirective implements OnInit, OnDestroy {

  private subscription: Subscription | null = null;

  //BFS: 1.10.3 - this comes from sidebar.config.ts where it tells you who can see this or not
  @Input() isVisibleForRoles: string[] | null = null;
  @Input() isVisibleForEntities: string[] | null = null;

  constructor(private elem: ElementRef, private authService: AuthService) { }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.subscription = this.authService.authStatus$.subscribe(status => this.changeVisibility(status));
    this.changeVisibility(this.authService.isAuthUserLoggedIn());
  }

  private changeVisibility(status: boolean) {
    const isInRoles = !this.isVisibleForRoles ? true : this.authService.isAuthUserInRoles(this.isVisibleForRoles);
    //BFS: 1.10.3 - if there is no isVisibleForEntities (meaning its not specified in the sidebar.config.ts then true or show but if there is then go check for it here this.authService.isAuthUserInEntities(this.isVisibleForEntities)
    //and if whats in sidebar.config.ts matches what entity you are in then show
    const isInEntities = !this.isVisibleForEntities ? true : this.authService.isAuthUserInEntities(this.isVisibleForEntities);
    var display;
    if (!this.authService.isAuthUserInRoles(['Admin'])) { // || !this.authService.isAuthUserInRoles(['Special'])
      //BFS: 1.10.3 - if ur not an admin then show 'none' or nothing
      display = isInRoles && status && isInEntities ? "" : "none";
    } else {
      //BFS: 1.10.3 - if ur an admin then check to see if the isInEntities true or false and then show accordingly
      display = status && isInEntities ? "" : "none";
      //BFS: 1.10.3 - it was like this
      //display = ""'
    }
    this.elem.nativeElement.style.display = display;
  }
}
