import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {

  @Input()
  message: string = '';
  @Input()
  title: string = '';
  constructor(
      public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
    
  }
  
  save() {
      this.activeModal.close();
  }

  close() {
      this.activeModal.dismiss();
  }
}


