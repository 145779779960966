import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';



@Component({
  selector: 'app-grid-action',
  templateUrl: './grid-action.component.html',
  styleUrls: ['./grid-action.component.css']
})
export class GridActionComponent implements ICellRendererAngularComp {

  public params: any;
  public isSaveVisible: boolean = false;
  agInit(params: any): void {
    this.params = params;
    this.isSaveVisible = this.params.isSaveVisible || false;
   
  }

  refresh(_params?: any): boolean {
    return true;
  }

 
  public onSave($event: any) {
   
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        actionType:  'save'
      }
      this.params.onClick(params);
    }
  }

  
}
