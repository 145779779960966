export enum ServerErrors {
  CANNOT_BE_DELETED_PROPERTY = 'Property cannot be deleted. Property has one or more reports. Delete all reports prior to deleting this property.',
  CANNOT_BE_DELETED_COMPANIES = 'Management company cannot be deleted. Management company has one or more users. Remove assignment to associated user prior to deleting this company.',
  CANNOT_BE_DELETED_COMPANIES2 = 'Management company cannot be deleted. Management company has one or more properties. Remove assignment to associated properties or delete them prior to deleting this company.',
  CANNOT_BE_DELETED_COMPANIES3 = 'Management company cannot be deleted. Management company has one or more users. Remove assignment to associated user prior to deleting this company. Management company has one or more properties. Remove assignment to associated properties or delete them prior to deleting this company.',
  CANNOT_BE_DELETED_ADDITIONAL_TARGETINGS = 'Property cannot be deleted. Property has one or more additional targetings. Delete all additional targetings prior to deleting this property.',
  CANNOT_BE_DELETED_DEEP_RENT_SKEWINGS = 'Property cannot be deleted. Property has one or more deep rent skewings. Delete all deep rent skewings prior to deleting this property.',
  CANNOT_BE_DELETED_PROPERTY_BUILDINGS = 'Property cannot be deleted. Property has one or more buildings. Delete all buildings prior to deleting this property.',
  CANNOT_BE_DELETED_BUILDING = 'Building cannot be deleted. Building has one or more units. Delete all untis prior to deleting this Building.'
}
