export interface TrackingItem {
    startDate: string | Date| null;
    propertyId: number;
    trackingItemID: number;
    trackingItemName: string;
    frequencyTypeID: number;
    sendWithAttachment: boolean;
    // trackingItemFileID: number;
    backUpDocRequired: boolean;
    frequencyTypeName: string;
    active: boolean;
    filePath: string;
    fileName: string;
    fileID: number;
    trackingNameFrequencyName: string;
    disableSaveButton: number;
}
export class TrackingItemObject implements TrackingItem{
    startDate: string | Date| null;
    propertyId: number;
    trackingItemID: number;
    trackingItemName: string;
    frequencyTypeID: number;
    sendWithAttachment: boolean;
    // trackingItemFileID: number;
    backUpDocRequired: boolean;
    frequencyTypeName: string;
    active: boolean;
    filePath: string;
    fileName: string;    
    fileID: number;
    trackingNameFrequencyName: string;
    disableSaveButton: number;

    
    public constructor(){
        this.startDate = null;
        this.propertyId = 0;
        this.trackingItemID = 0;
        this.trackingItemName = '';
        this.frequencyTypeID = 0;
        this.sendWithAttachment = false;
        // this.trackingItemFileID = 0;
        this.backUpDocRequired = false;
        this.frequencyTypeName = '';
        this.active = true;
        this.filePath = '';
        this.fileName = '';
        this.fileID = 0;
        this.trackingNameFrequencyName = '';
        this.disableSaveButton = 0;

    }
}