import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, User } from '@app/core';
import { UserGridColumn } from '@app/core/models/user-grid-column';

@Injectable({ providedIn: 'root' })
export class ManagementGridService {
    constructor(private http: HttpClient) { }

    getGridColumns(gridName: string) {
        return this.http.get<UserGridColumn[]>(`${AppConfig.apiEndpoint}/users/columns/${gridName}`);
    }

    updateGridColumns(userGridColumn: UserGridColumn) {
        return this.http.put(`${AppConfig.apiEndpoint}/users/columns`, userGridColumn);
    }

}