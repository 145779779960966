<div class="modal-header">
  <h4 class="modal-title col-md-8">{{ dialogTitle }}</h4>
 <!-- <button type="button" class="btn col-md-1 lock-notify-button-margin" (click)="lock()" *ngIf="!form2.controls['buildingLocked'].value && isAdmin">
    <i class="fa fa-unlock-alt" aria-hidden="true"></i>
  </button>
  <button type="button" class="btn col-md-1 lock-notify-button-margin" (click)="unlock()" *ngIf="form2.controls['buildingLocked'].value && isAdmin">
    <i class="fa fa-lock" aria-hidden="true"></i>
  </button>-->
  <button type="button" class="btn col-md-2 lock-notify-button-margin" (click)="email()" *ngIf="form2.controls['buildingLocked'].value && !isAdmin">
    NOTIFY
  </button>
  <button type="button" class="close col-md-1 close-margin" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" style="height:400px">
  <form [formGroup]="form">
    <!--row 1-->
    <div class="form-row">
      <div class="form-group col-md-6 required">
        <label class="control-label">Building No:</label>
        <input class="form-control" formControlName="buildingNumber" [attr.disabled]="form2.controls['buildingLocked'].value ? '' : null" />
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('buildingNumber')!.touched) && form.get('buildingNumber')!.hasError('required')">
          This field is required
        </span>
      </div>
    </div>

    <div class="form-row">

      <div class="form-group col-md-12">
        <label class="control-label">Certification of Occupancy / Placed in Service Date:</label><i style="margin-left:12px;" class="question-space fa fa-question-circle hover-pointer" [ngbPopover]="certOfOccupancyPopover"
                                                                                                         triggers="mouseenter:mouseleave" placement="left"></i>
        <div class="input-group">
          <input class="form-control" formControlName="certOfOccupancy" name="dp" [disabled]="form2.controls['buildingLocked'].value"
                 [class.error]="(isFailedForm ||form.get('certOfOccupancy')!.touched) && form.get('certOfOccupancy')!.hasError('dateInvalid')"
                 [minDate]="{year: 1960, month: 1, day: 1}" [maxDate]="{year: 2050, month: 1, day: 1}"
                 ngbDatepicker #d1="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" [disabled]="form2.controls['buildingLocked'].value" (click)="d1.toggle()" type="button"></button>
          </div>
        </div>
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('certOfOccupancy')!.touched) && form.get('certOfOccupancy')!.hasError('dateInvalid')">
          Invalid Date
        </span>
      </div>
    </div>
    <!--row 2-->
    <div class="form-row" style="padding-top:20px;">
      <div class="form-group col-md-6">
        <label class="control-label">BIN:</label>
        <input class="form-control" formControlName="bin" [attr.disabled]="form2.controls['buildingLocked'].value ? '' : null" />
      </div>
      <div class="form-group col-md-6">
        <label class="control-label">Existing LIHTC Project:</label>
        <input class="form-control" type="checkbox" formControlName="lihtcProject" [attr.disabled]="form2.controls['buildingLocked'].value ? '' : null"  />
      </div>
    </div>
     <!--row 3-->
     <div class="form-row">
      <div class="form-group col-md-6">
        <label class="control-label">Crediting Year:</label>
        <input class="form-control" formControlName="creditingYear" [attr.disabled]="form2.controls['buildingLocked'].value ? '' : null" type="number" />
        <span class="error-text"
              *ngIf="(isFailedForm ||form.get('creditingYear')!.touched) && form.get('creditingYear')!.hasError('pattern')">
          Crediting year should be a valid year
        </span>
      </div>
      </div>
      
   
  </form>
</div>

<div class="modal-footer">
  <div *ngIf="!isAdd" class="w-100">
    <div class="row">
      <div class="col-3">Created:</div>
      <div class="col">
        <small>
          {{form.get("createdByName")!.value}},
          {{ form.get("createdDate")!.value | date : "short" }}
        </small>
      </div>
    </div>
    <div class="row">
      <div class="col-3">Modified:</div>
      <div class="col">
        <small>
          {{form.get("updatedByName")!.value}},
          {{ form.get("updatedDate")!.value | date : "short" }}
        </small>
      </div>
    </div>
  </div>
  <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
  <button [disabled]="!form.valid" type="button" class="btn btn-primary" (click)="save()">Save</button>
</div>
