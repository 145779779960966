<div class="modal-header">
    <h4 class="modal-title col-md-8">{{ dialogTitle }}</h4>
    <button type="button" class="close col-md-1 close-margin" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body" style="height:200px">
    <form [formGroup]="form">
      <!--row 1-->
      <div class="form-row">
        <div class="form-group col-md-6 required">
          <label class="control-label">Role Name:</label>
          <input class="form-control" formControlName="name"  />
          <span class="error-text"
                *ngIf="(isFailedForm ||form.get('name')!.touched) && form.get('name')!.hasError('required')">
            This field is required
          </span>
        </div>
      </div>
  
     
    </form>
  </div>
  
  <div class="modal-footer">
    
    <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
    <button [disabled]="!form.valid" type="button" class="btn btn-primary" (click)="save()">Save</button>
  </div>
  