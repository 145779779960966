import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FileUploaderService } from './file-uploader.service';
import { DialogsService } from '@app/core/services/dialogs.service';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { AppConfig, TokenStoreService } from '@app/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {  
  @Input('params') 
  set params(params: any){
    this.uploadFile(params);
  };
  @Input() entity!: string;
  @Input() url!: string;
  @Input() fileName!: string;
  @Input('sendingIndex') 
  set sendingIndex(sendingIndex: number){
    this.sendBackIndex = sendingIndex;
  };
  public sendBackIndex = 0;
  //BFS: 9/16/2022 - 01.11 - this output emmitter passes data to the parent component and in the parent component we need a handler to handle this data coming in
  @Output() valueChange = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Output() valueChangeAdd = new EventEmitter();
  @Output() indexChange = new EventEmitter();
  public fileUploader: any;

  constructor(
    private fileUploaderService: FileUploaderService,
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    private tokenStoreService: TokenStoreService,
    private dialogsService: DialogsService,
  ) { }

  ngOnInit() {
    this.fileUploader = this.fileUploaderService.add(this.params);

    this.fileUploader.onAfterAddingFile=(file: any)=>{
      file.withCredentials = false;
      if(this.entity === 'pef'){
        if(this.fileUploader.queue.length > 1){
          this.fileUploader.queue.splice(0,1);
        }
      }

      if(this.entity === 'wnc'){
      }

      if(this.entity === 'cpp'){
        if(this.fileUploader.queue.length > 1){
          this.fileUploader.queue.splice(0,1);
        }
      }

      this.valueChange.emit(file);
      this.indexChange.emit(this.sendBackIndex);
    }

    this.fileUploader.onSuccessItem = (_item: any, _response: string) => {
      let file = JSON.parse(_response);
      this.valueChange.emit(file);
    };

    this.fileUploader.onErrorItem = (_item: any, res: string) => {
      this.showErrorMessage(res);
    };
    
    this.fileUploader.onCompleteItem=(_item: any, _response: any, _status: any, _header: any)=>{
      if(this.entity === 'cpp'){
        var file = JSON.parse(_response);
        this.valueChange.emit(file);
      }
      if(this.entity === 'pef'){
        this.closeModal.next(true);
      }
    }
  }

  public uploadFile(params: any) {
    if(params){
      if(params.entity){
        this.fileUploaderService.setOptions(params, params.entity);
      } else {
        this.fileUploaderService.setOptions(params, this.entity);
      }
    }
  }

  public getFileFromServer(filePath: string) {
    var filename =filePath.split('/').pop()
     this.fileUploaderService.downloadFile(filePath).subscribe((res: any) => {
       const fileType = res.headers.get('Content-Type')
       const blob = new Blob([res.body], { type: fileType});
       const fileURL = window.URL.createObjectURL(blob);
       const a = document.createElement("a");
       document.body.appendChild(a);
       a.style.cssText = "display:none";
       a.href = fileURL;
       a.setAttribute('download', filename);
      
       a.click();
       window.URL.revokeObjectURL(fileURL);
     
     },() => {
      
     });
   }

  showErrorMessage(message:string) {
    return this.dialogsService.error(message)
  }
}
