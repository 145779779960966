import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService, Credentials } from "@app/core";
import { MsalService } from "@azure/msal-angular";

@Component({
  selector: "app-ssoauth",
  templateUrl: "./ssoAuth.component.html",
  styleUrls: ["./ssoAuth.component.css"]
})
export class SSOAuthComponent implements OnInit {

  model: Credentials = { username: "", password: "", rememberMe: false };
  error = "";
  returnUrl: string | null = null;
  public entity: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private msalAuthService: MsalService) { 
      this.returnUrl = this.route.snapshot.queryParams["returnUrl"];
      this.loginWithSSOAuth();
    }

  ngOnInit() {
    // reset the login status
   
    //this.authService.logout(false);

    // get the return url from route parameters
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"];
    //this.loginWithSSOAuth();


  }

  loginWithSSOAuth() {
    this.error = "";
    this.authService.ssoAuthOnAPI()
      .subscribe(isLoggedIn => {
        if (isLoggedIn) {
          if (this.returnUrl) {
            this.router.navigate([this.returnUrl]);
          } else {
            var isAdmin = this.authService.isAuthUserInRole("Admin");
            this.entity = this.authService.authUserEntity();

            if (isAdmin === true) {
              this.router.navigate(["/dashboard"]);
            }
            if (isAdmin === false && this.entity.indexOf('wnc') === 0){
              this.router.navigate(["/unitDetails"]);
            }
            if ((isAdmin === false && this.entity.indexOf('cpp') === 0)){
              this.router.navigate(["/reports"]); 
            }
            if ((isAdmin === false && this.entity.indexOf('pef') === 0)){
              this.router.navigate(["/reportsPEF"]); 
            }

          }
        }
      },
        (error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.error = "Invalid User name or Password. Please try again.";
          } else {
            this.error = `${error.statusText}: ${error.message}`;
          }
          
        });

        this.router.navigate(["/login"]); 
  }

 
}
