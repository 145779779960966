import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'notify-reason-dialog',
  templateUrl: './notify-reason-dialog.component.html',
  styleUrls: ['./notify-reason-dialog.component.css']
})
export class NotifyReasonDialogComponent implements OnInit {
    form: UntypedFormGroup;
    @Input() 
    reason: any;
    public isFailedForm:boolean = false;
    
    constructor(
        private fb: UntypedFormBuilder,
        public activeModal: NgbActiveModal,
        //service
        ) {
      
        this.form = this.fb.group({
            reason: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
        }, {
        });
    }

    ngOnInit() {
        this.form.patchValue(this.reason);
    }

    save() {
        if (this.form.invalid) {
            this.isFailedForm = true;
        } else {
            console.log('%c going into save', 'background: yellow; color: black', this.form);
            this.activeModal.close(this.form);
        }
    }

    close() {
        this.activeModal.dismiss();
    }
}
