<div class="modal-header">
    <h4 class="modal-title">Profile</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="form">

        <div class="form-row">  
            <div class="form-group required col-md-6">
               <label class="control-label">Name:</label>
                <input class="form-control" formControlName="displayName"  
                [class.error]="(isFailedForm ||form.get('displayName')!.touched) && form.get('displayName')!.invalid"/>
                <span class="error-text" *ngIf="(isFailedForm ||form.get('displayName')!.touched) && form.get('displayName')!.hasError('required')">
                    This field is required</span>
                <span class="error-text" *ngIf="(isFailedForm ||form.get('displayName')!.touched) && form.get('displayName')!.hasError('minlength')">
                    Should contain at least 2 characters</span>
            </div>
            
            <div class="form-group required col-md-6">
               <label class="control-label">Username:</label>
                <input class="form-control" formControlName="username" 
                [class.error]="(isFailedForm ||form.get('username')!.touched) && form.get('username')!.invalid" />
                <span class="error-text" *ngIf="(isFailedForm ||form.get('username')!.touched) && form.get('username')!.hasError('required')">
                    This field is required</span>
                <span class="error-text" *ngIf="(isFailedForm ||form.get('username')!.touched) && form.get('username')!.hasError('minlength')">
                    Should contain at least 2 characters</span>
            </div>

            <div class="form-group required col-md-6">
               <label class="control-label">Email:</label>
                <input class="form-control" formControlName="email"  
                [class.error]="(isFailedForm ||form.get('email')!.touched) && form.get('email')!.invalid"/>
                <span class="error-text" *ngIf="(isFailedForm ||form.get('email')!.touched) && form.get('email')!.hasError('required')">
                    This field is required</span>
                <span class="error-text" *ngIf="(isFailedForm ||form.get('email')!.touched) && form.get('email')!.hasError('email')">
                    Please, type valid email</span>
            </div>

            <div class="form-group col-md-6">
               <label class="control-label">Phone Number:</label>
                <input class="form-control" formControlName="phoneNumber" />
            </div>
        </div>

    </form>
    <div *ngIf="user.needToChangePassword" class="text-danger mb-3">
        Please, change your password
     </div>
    <button *ngIf="!isAD" class="btn btn-outline-dark" (click)="openChangePassword()">Change Password</button>

</div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
      <button type="button" class="btn btn-primary" (click)="save()">Save changes</button>
    </div>