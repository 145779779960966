export interface FrequencyType {
    frequencyTypeID: number;
    frequencyTypeName: string;
    year: number;
    month: number;
    day: number;
    active: boolean;
   
}
export class FrequencyTypeObject implements FrequencyType{
    frequencyTypeID: number;
    frequencyTypeName: string;
    year: number;
    month: number;
    day: number;
    active: boolean;
   
    public constructor(){
        this.frequencyTypeID = 0;
        this.frequencyTypeName = '';
        this.year = 0;
        this.month = 0;
        this.day = 0;
        this.active = true;
    }
}