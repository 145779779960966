<button class="btn btn-light btn-sm" title="Edit" (click)="onEdit()">
  <i class="fa fa-pencil" aria-hidden="true"></i>
</button>

<button class="btn btn-danger btn-sm" *ngIf= "(!isUserGrid && Admin)" title="Delete" (click)="onDelete()">
  <i class="fa fa-trash" aria-hidden="true"></i>
</button>

<button class="btn btn-danger btn-sm" *ngIf= "(isUserGrid && isUserActive)" title="Deactivate" (click)="onDelete()">
    <i class="fa fa-ban" aria-hidden="true"></i>
</button>
