import { Component, TemplateRef, Output, EventEmitter, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
//import { DropDownService } from '@app/shared/dialogs/drop-down-edit-dialog/drop-down-edit-dialog.service';
//import { MarketAnalysisService } from '@app/marketAnalysis/marketAnalysis.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DialogsService } from '@app/core/services/dialogs.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TrackingItemAddDialogService } from '../tracking-item-add-dialog/tracking-item-add-dialog.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//import { ProjectHeaderService } from '../project-header-add-dialog/project-header-add-dialog.service';
//import { ProjectSummaryNoteHeader, ProjectSummaryNoteTitle, ProjectSummaryNoteTitleObject } from '@app/core';
//import { TrackingItemService } from './tracking-item-add-dialog.service';
import { TrackingItem, TrackingItemObject } from '@app/core';
//import { AnimateChildAst } from '@angular/animations/browser/src/dsl/animation_ast';
import { PreventiveMaintenanceService } from '@app/preventiveMaintenance/preventiveMaintenance.service';
import { FrequencyType, FrequencyTypeObject } from '@app/core/models/frequencyType';
import { Files } from '@app/core/models/files';
import { FileUploaderComponent } from '@app/shared/components/file-uploader/file-uploader.component';
import { FrequencyAddDialogService } from './frequency-add-dialog.service';

@Component({
  selector: 'frequency-add-dialog',
  templateUrl: './frequency-add-dialog.component.html',
  styleUrls: ['./frequency-add-dialog.component.css'],
  //providers:[BsModalService]
})
export class FrequencyAddDialogComponent implements OnInit { 

  @Input()
  // propertyComparable!: PropertyComparable;
  @Output() closeModalEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild(FileUploaderComponent) child!: FileUploaderComponent;


  // addForm: FormGroup;
//   headerForm: FormGroup;
  // newComparableDetail: FormGroup;
  public eventCloseModal: EventEmitter<any> = new EventEmitter();
  //public newTitle: boolean = false;
  public newFrequencyTypeShowDiv: boolean = false;
  public isFailedForm: boolean = false;
  //public newTitleItem: ProjectSummaryNoteTitle;
  public newFrequencyType: FrequencyType = new FrequencyTypeObject();
  public frequencyTypeList: FrequencyType[] = [];
  public repeatedForms: any;
  //BFS: 10/21/2022 - 01.11 - this is the parameters that get passed into the file uploader. *propId, reportId and such
  public isAdd = false;

  constructor(
    private modalRef: BsModalRef,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    public activeModal: NgbActiveModal,
    public ngbModal: NgbModal,
    //private dialogsService: DialogsService,
    private frequencyService: FrequencyAddDialogService,
    private dialogsService: DialogsService,
    private preventiveMaintenanceService: PreventiveMaintenanceService,
    ) { 

    }

  ngOnInit() {
    console.log('%c headers from parent adfasdfasdfasdfasdf', 'background: yellow; color: black', this.frequencyTypeList);
    //this.getFrequencyTypes();
    //this.selectedHeaderID = this.headers[0].projectSummaryNoteHeaderID
    //this.getTitles(this.selectedHeaderID);
    // this.projectHeaderService.getProjectSummaryHeaders().subscribe(res=>{
    //   this.headers = res;
    //   this.selectedHeaderID = this.headers[0].projectSummaryNoteHeaderID
    //   this.getTitles(this.selectedHeaderID);
    // });
  }

  public showNewFrequencyTypeDiv(){
    this.newFrequencyTypeShowDiv = true;
  }

  public closeNewFrequencyTypeDiv(){
    this.newFrequencyTypeShowDiv = false;
    this.newFrequencyType = new FrequencyTypeObject();
  }

  public addFrequencyType(item: FrequencyType){
    if(item.frequencyTypeName === ''){
        this.dialogsService.alert('error', 'The frequency type name cannot be blank.');
    } else{
        //BFS: 11/1/2022 - 01.11 - these 2 lines checks to see if the name are the same but in this case the names can be same
        const newArray = this.frequencyTypeList.map(el => el.frequencyTypeName.toLowerCase());
        const found = newArray.some (el => el === item.frequencyTypeName.toLowerCase());
        if(found === true){
            this.dialogsService.alert('error', 'The frequency type name already exists in the list.');
            item.frequencyTypeName = '';
        } else {
            this.isAdd = true;
            this.frequencyService.addFrequencyType(item).subscribe(res => {
                item.frequencyTypeID = res;
                this.frequencyTypeList.push(item);
                this.closeNewFrequencyTypeDiv();
                const title = 'Success';
                const message = `Frquency type succesfully added.`;
                this.dialogsService.alert(title, message);
            });
        }
    }
  }

  public saveFrequencyType(item: FrequencyType, index: number){
    console.log('%c frequecntyTYpe', 'background: yellow; color: black', item, index);
    if(item.frequencyTypeName === ''){
      this.dialogsService.alert('error', 'The frequency type name cannot be blank.');
      this.getFrequencyTypes();
    } else {
        this.isAdd = false;
        console.log('%c update', 'background: yellow; color: black', item);
        this.frequencyService.updateFrequencyType(item).subscribe(_res => {
          const title = 'Success';
          const message = `Tracking item succesfully saved.`;
          this.dialogsService.alert(title, message);
        })
    }
  }

  public deleteFrequencyType(item: FrequencyType, index: number){
    console.log('%c delete frequency type', 'background: yellow; color: black', item, index);
    const popUpTitle = 'Delete Frequency type' ;
    const message = `Are you sure you want to delete this frequency type?`;
    this.dialogsService.confirmation(popUpTitle, message)
      .then(() => {
        this.frequencyService.deleteFrequencyType(item.frequencyTypeID).subscribe(_res=>{      
        const title = 'Success';
        const message = `Tracking item succesfully deleted`;
        this.dialogsService.alert(title, message);
          this.frequencyTypeList.splice(index, 1);
        },(error) => {
          const title = 'Error';
          const message = error.error;
          this.dialogsService.alert(title, message)
        });
      });
  }

  public closeModal(){
    this.frequencyTypeList = [];
    this.activeModal.dismiss();
    this.eventCloseModal.emit({});
  }

  private getFrequencyTypes(){
    this.preventiveMaintenanceService.getFrequencyTypes().subscribe(res=>{
      this.frequencyTypeList = res;
    });
  }
}