import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '@app/core';
import { map } from 'rxjs/operators';
import { TrackingItem } from '@app/core'

@Injectable({ providedIn: 'root' })
export class TrackingItemAddDialogService {
    constructor(private http: HttpClient) { }
    
    addTrackingItem(model: TrackingItem){
        return this.http.post<number>(`${AppConfig.apiEndpoint}/preventiveMaintenance/addTrackingItem`, model);
    }

    updateTrackingItem(model: TrackingItem){
        return this.http.put(`${AppConfig.apiEndpoint}/preventiveMaintenance/updateTrackingItem`, model);
    }

    deleteTrackingItem( trackingItemID: number){
        return this.http.delete(`${AppConfig.apiEndpoint}/preventiveMaintenance/deleteTrackingItem/${trackingItemID}`);
    }

}
