import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

  @Input()
  title: string = '';
  @Input()
  message: string = '';
  @Input()
  okText: string = '';
  @Input()
  cancelText: string = '';

  constructor(
      public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
    this.title = this.title || 'Confirmation';
    this.okText = this.okText || 'Yes';
    this.cancelText = this.cancelText || 'Cancel';
  }
  
  save() {
      this.activeModal.close();
  }

  close() {
      this.activeModal.dismiss();
  }

}
