<div class="modal-header">
    <h4>Reason for unlock request:</h4>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <textarea style="resize: none;" rows="9" class="form-control" type="text" formControlName='reason'></textarea>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
    <button [disabled]="!form.valid" type="button" class="btn btn-primary" (click)="save()">Save</button>
</div>
