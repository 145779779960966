import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FileUploadModule, FileDropDirective } from 'ng2-file-upload';
import {CurrencyMaskModule} from "ng2-currency-mask";
import {InputMaskDirective} from './directives/text-mask.directive'
import { EqualValidatorDirective } from "./directives/equal-validator.directive";
import { HasAuthUserViewPermissionDirective } from "./directives/has-auth-user-view-permission.directive";
import { IsVisibleForAuthUserDirective } from "./directives/is-visible-for-auth-user.directive";
import { AgGridModule } from "ag-grid-angular";
import { UserAddDialogComponent } from "./dialogs/user-add-dialog/user-add-dialog.component";
import { BuildingAddDialogComponent } from "./dialogs/building-add-dialog/building-add-dialog.component";
import { UnitAddDialogComponent } from "./dialogs/unit-add-dialog/unit-add-dialog.component";
import { PropertyAddDialogComponent } from "./dialogs/property-add-dialog/property-add-dialog.component";
import { CompanyAddDialogComponent } from "./dialogs/company-add-dialog/company-add-dialog.component";
import { ReportAddDialogComponent } from "./dialogs/report-add-dialog/report-add-dialog.component";
import { ProfileDialogComponent } from "./dialogs/profile-dialog/profile-dialog.component";
import { PasswordChangeDialogComponent } from "./dialogs/password-change-dialog/password-change-dialog.component";
import { ManagementGridComponent } from "./components/management-grid/management-grid.component";
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';
import { ManagementGridActionComponent } from './components/management-grid-action/management-grid-action.component';
import { ChangePasswordService } from "@app/authentication/change-password/services/change-password.service";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TextMaskModule } from 'angular2-text-mask';;
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { AlertDialogComponent } from './dialogs/alert-dialog/alert-dialog.component';
import { FilesDialogComponent } from './dialogs/files-dialog/files-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { NotifyReasonDialogComponent } from "./dialogs/notify-reason-dialog/notify-reason-dialog.component";
import { ReportPEFAddDialogComponent } from "./dialogs/reportPEF-add-dialog/reportPEF-add-dialog.component";
import { ModalModule } from 'ngx-bootstrap/modal';
import { TrackingItemAddDialogComponent } from "./dialogs/tracking-item-add-dialog/tracking-item-add-dialog.component";
import { FrequencyAddDialogComponent } from "./dialogs/frequency-add-dialog/frequency-add-dialog.component";
import { UnitCPPAddDialogComponent } from "./dialogs/unitCPP-add-dialog/unitCPP-add-dialog.component";
import { BuildingCPPAddDialogComponent } from "./dialogs/buildingCPP-add-dialog/buildingCPP-add-dialog.component";
import {MatListModule} from '@angular/material/list';
import { RoleAddDialogComponent } from "./dialogs/role-add-dialog/role-add-dialog.component";
import { NotificationManagementDialogComponent } from "./dialogs/notification-management-dialog/notification-management-dialog.component";
//import { NgxModalDraggableDirective } from './directives/ngxBootstrap-draggable.directive';




@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgbModule,
        TextMaskModule,
        FileUploadModule,
        AgGridModule,
        MatInputModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatListModule,
        CurrencyMaskModule,
        ModalModule.forRoot(),
    ],
    schemas: [],
    declarations: [
        // common and shared components/directives/pipes between more than one module and components will be listed here.
        IsVisibleForAuthUserDirective,
        HasAuthUserViewPermissionDirective,
        EqualValidatorDirective,
        UserAddDialogComponent,
        PropertyAddDialogComponent,
        CompanyAddDialogComponent,
        ReportAddDialogComponent,
        ReportPEFAddDialogComponent,
        ProfileDialogComponent,
        PasswordChangeDialogComponent,
        NotifyReasonDialogComponent,
        ManagementGridComponent,
        ConfirmationDialogComponent,
        ErrorDialogComponent,
        AlertDialogComponent,
        ManagementGridActionComponent,
        FileUploaderComponent,
        AlertDialogComponent,
        BuildingAddDialogComponent,
        UnitAddDialogComponent,
        FilesDialogComponent,
        InputMaskDirective,
        TrackingItemAddDialogComponent,
        FrequencyAddDialogComponent,
        UnitCPPAddDialogComponent,
        BuildingCPPAddDialogComponent,
        RoleAddDialogComponent,
        NotificationManagementDialogComponent
        //NgxModalDraggableDirective,
        //FileDropDirective,
        //BFS: 1.10.8 - 11/10/2021
        //WeeklyComplianceOccupancyReportAddDialogComponent,
    ],
    providers: [ChangePasswordService],
    exports: [
        // common and shared components/directives/pipes between more than one module and components will be listed here.
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        IsVisibleForAuthUserDirective,
        HasAuthUserViewPermissionDirective,
        EqualValidatorDirective,
        ManagementGridComponent,
        AgGridModule,
        FileUploaderComponent,
        NgbModule,
        MatInputModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatListModule,
        InputMaskDirective,
        //NgxModalDraggableDirective,
    ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    // Forcing the whole app to use the returned providers from the AppModule only.
    return {
      ngModule: SharedModule,
      providers: [ /* All of your services here. It will hold the services needed by `itself`. */]
    };
  }
}
