import { Component, OnInit, Inject, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { User } from '@app/core/models/user';
import { DialogsService } from '@app/core/services/dialogs.service';
import { emailValidator, passwordValidator, existValidator } from '@app/shared/validators';
import { UserService } from '@app/users/user.service';
import { CompanySimple } from '@app/core/models/companySimple'
import { Observable } from 'rxjs';
import { startWith, map } from  'rxjs/operators';

@Component({
  selector: 'app-user-add-dialog',
  templateUrl: './user-add-dialog.component.html',
  styleUrls: ['./user-add-dialog.component.css'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, DatePipe]
})
export class UserAddDialogComponent implements OnInit {
  dialogTitle = '';
  isAdd = true;
  isActiveDirectory = false;
  form: UntypedFormGroup;
  @Input()
  user!: User;
  users!: any;
  model: any;
  public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public isFailedForm: boolean = false;
  public companies: CompanySimple[] = [];
  public filteredCompanyList: Observable<CompanySimple[]> | undefined;
  public userList: any[] = [];
  public userListForUpdate: any[] = [];
  public entityPopover: string = "On rare occations, the user will have 2 entities in order to see both the units page (WNC) and the reports page (CPP)."
  authService: any;
  public isAdmin: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private datePipe: DatePipe,
    private userService: UserService,
    private dialogsService: DialogsService) {

    this.form = this.fb.group({
      id: [0, []],
      displayName: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      username: ['', []],
      companyId: ['', Validators.required],
      isActive: [false, []],
      newPassword: ['', []],
      email: ['', Validators.compose([Validators.required, emailValidator])],
      phoneNumber: ['', []],
      managementCompany: ['', []],
      ipAddress: ['', Validators.pattern(/^([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3})[.]([0-9]{1,3})$/)],
      lockoutEnabled: [false, []],
      lockoutEnd: [null, []],
      twoFactorEnabled: [false, []],
      createdById: ['', []],
      createdByName: ['', []],
      createdDate: [null, []],
      updatedById: ['', []],
      updatedByName: ['', []],
      updatedDate: [null, []],
      isAdmin: [false, []],
      entity: ['', []],
      isWNC: [false, []],
      isCPP: [false, []],
      isPEF: [false, []],
    });
  }

  ngOnInit() {
    this.isAdd = !(this.user && this.user.id);
    this.isActiveDirectory = this.user.isActiveDirectory;
    //this.isAdmin = this.authService.isAuthUserInRole("Admin");
    if(this.user.isAdmin === true){
      this.isAdmin = true;
    }

    if (this.isAdd) {
      this.form.get('newPassword')!.setValidators(Validators.compose([Validators.required, passwordValidator]));
      this.form.get('newPassword')!.updateValueAndValidity();
    } else {
      this.users = this.users.filter((e: string) => e !== this.user.username);
      this.form.get('newPassword')!.setValidators([]);
      this.form.get('newPassword')!.updateValueAndValidity();
    }

    this.form.get('username')!.setValidators(Validators.compose([Validators.required, Validators.minLength(2), existValidator(this.users)]));
    this.form.get('username')!.updateValueAndValidity();

    this.form.patchValue(this.user);
    this.dialogTitle = this.isAdd
      ? 'Add User'
      : 'Update User';

    this.filters();

    //BFS: 1.081 - 11.19.2020 on save, the user list gets updated by splicing out the email that has the same email from the form that got filled into the modal
    //(this is done so that if you want to just save again using the same email addess, it will let you, but! if you change your addess to anythting 
    //that still exists in the users list it wont let you update. this is done to prevent from users updating the current user with the same email as other users on the list)
    console.log('%c this form', 'background: yellow; color: black', this.form);
    this.userListForUpdate = this.userList.filter(item => item.email !== this.form.value.email);
    console.log('%c user userListForUpdateuserListForUpdate', 'background: yellow; color: black', this.userListForUpdate);
  }

  public filters() {
    this.filteredCompanyList = this.form.controls['companyId'].valueChanges
      .pipe(
        startWith<string | CompanySimple>(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._companyFilter(name) : this.companies.slice())
    );
  }

  private _companyFilter(name: string): CompanySimple[] {
    const filterValue = name.toLowerCase();
    return this.companies.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  public displayFnCompany(options: CompanySimple[] | null): (companyId: number) => string | null {
    return (companyId: number) => {
      const correspondingOption = Array.isArray(options) ? options.find(option => option.companyId === companyId) : null;
      return correspondingOption ? correspondingOption.name : '';
    }
  }
  
  resetPassword() {
    const title = 'Reset password';
    const message = `Are you sure you want to reset a password for user ${this.user.displayName || this.user.username}?`;

    this.dialogsService.confirmation(title, message)
      .then(() => {
        return this.userService.resetPassword(this.user).subscribe((res) => {
          console.log("UPDATED USER", res);
          this.user = res;
          this.form.patchValue(this.user);
          this.form.updateValueAndValidity();

          const title = 'Success';
          const message = `Message succesfully sent`;
          this.dialogsService.alert(title, message)
        },
          () => {
            const message = 'Cannot be reset!';
            this.dialogsService.error(message)
          });
      }, () => { });
  }

  save() {
    console.log('%c this.form', 'background: yellow; color: black', this.form);
    if (this.form.invalid) {
      console.log('%c invalid', 'background: yellow; color: black', );
      this.isFailedForm = true;
    } else {
      if(this.form.value.id > 0){
        //BFS: 1.081 - now it will check the email in the form with the existing emails to see if you chose something that already exists. if not found (false) then update (the list was updated in the init)
        //TT: email duplicate check removed as part of feature #1477 DROP Unique email requirements when creating a new user
        const formValue = this.form.getRawValue();
        const user = Object.assign({}, this.user, formValue);
        console.log('%c useupdateduapteupudaptue', 'background: yellow; color: black', user);
        this.activeModal.close(user);
        
      } else {
        //BFS: 1.081 - when adding a new user, check for the email to make sure it does not have a 'cpp-housing.com' or 'wncincin.com'
        //WNC employees, if they wanted access to EPIQPM, need to be added to the active directory
        var email = this.form.value.email
        console.log('%c email', 'background: yellow; color: black', email);
        var backEmail = email.substring(email.indexOf('@') + 1);
        console.log('%c email after', 'background: yellow; color: black', backEmail);
       //TT: email duplicate check removed as part of feature #1477 DROP Unique email requirements when creating a new user
        if(backEmail === 'cpp-housing.com' || backEmail === 'wncinc.com' ){
          this.showErrorMessage('If you are trying to create an account for WNC/CPP employees, please contact the WNC Helpdesk in order to create an account for WNC users.');
        } else {
          console.log('%c saved right', 'background: yellow; color: black', );
          const formValue = this.form.getRawValue();
          const user = Object.assign({}, this.user, formValue);
          console.log('%c usereursuersueru', 'background: yellow; color: black', user);
          this.activeModal.close(user);
        }
      }
    }
  }

  close() {
    this.activeModal.dismiss();
  }

  showErrorMessage(message: string) {
    return this.dialogsService.error(message)
  }

}
